import React from "react";
import { Box } from "@mui/material";
import TituloSubtituloCentro from "../../../../components/texto/TituloSubtituloCentro/cuerpoEnElCentro";
import AcordeonPreguntasFrecuentes from "../../../../components/acordeones/PreguntasFrecuentes";

const SeccionConTituloYAcordeon = ({
  title,
  subtitle,
  titleColor = "#211E26",
  subtitleColor = "text.secondary",
  questions = [],
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 3,
        gap: 10,
      }}
    >
      {/* Título y subtítulo */}
      <TituloSubtituloCentro
        title={title}
        subtitle={subtitle}
        titleColor={titleColor}
        subtitleColor={subtitleColor}
      />

      {/* Acordeón debajo */}
      <AcordeonPreguntasFrecuentes questions={questions} />
    </Box>
  );
};

export default SeccionConTituloYAcordeon;
