import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Link,
  Box,
} from "@mui/material";

const CardSoluciones = ({ title, description, features, link }) => {
  return (
    <Card
      sx={{
        borderRadius: "16px",
        border: "1px solid #BDBDBD",
        maxWidth: 400,
        height: 500,
        padding: "16px",
      }}
    >
      <CardContent>
        {/* Título general */}
        <Typography variant="h4" component="div" align="center" gutterBottom>
          {title}
        </Typography>

        {/* Descripción */}
        <Typography variant="body2" align="center" color="text.secondary" gutterBottom>
          {description}
        </Typography>

        <Divider sx={{ marginY: 2, border: "1px solid rgb(101, 100, 100)" }} />

        {/* Características */}
        {features.map((feature, index) => (
          <Box key={index} display="flex" alignItems="center" flexDirection="column" gap={1} mt={2}>
            {feature.icon}
            <Typography variant="h6" component="div">
              {feature.title}
            </Typography>
            <Typography variant="body2" color="text.secondary" align="center" gutterBottom>
              {feature.description}
            </Typography>
          </Box>
        ))}

        {/* Enlace de más información */}
        {link && (
          <Box display="flex" alignItems="center" justifyContent="center" gap={1} mt={2}>
            {link.icon}
            <Link href={link.url} underline="hover" sx={{color: "#0b8db5"}}>
              {link.text}
            </Link>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default CardSoluciones;
