import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CampaignIcon from "@mui/icons-material/Campaign";
import SchoolIcon from "@mui/icons-material/School";
import PeopleIcon from "@mui/icons-material/People";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import WidgetsIcon from "@mui/icons-material/Widgets";
import CodeIcon from "@mui/icons-material/Code";
import WebIcon from "@mui/icons-material/Web";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import ContactMailIcon from "@mui/icons-material/ContactMail";

import Desplazable from "./desplazable/elementoDesplazable";

const marketing = [
  { name: "Redes Sociales", icon: <CampaignIcon sx={{ color: "#00B0FF" }} />, ruta: "/servicios/redes-sociales" },
  { name: "Publicidad Digital", icon: <MailIcon sx={{ color: "#FF9800" }} />, ruta: "/servicios/publicidad-digital" },
  { name: "Email Marketing", icon: <WidgetsIcon sx={{ color: "#FF5722" }} />, ruta: "/servicios/email-marketing" },
  { name: "Inbound Marketing", icon: <SchoolIcon sx={{ color: "#FFEB3B" }} />, ruta: "/servicios/inbound-marketing" },
  { name: "Asesoría de Inbound Marketing", icon: <PeopleIcon sx={{ color: "#8BC34A" }} />, ruta: "/servicios/asesoria-inbound" },
];

const desarrollo = [
  { name: "Creación de Páginas Web", icon: <WebIcon sx={{ color: "#00B0FF" }} />, ruta: "/servicios/desarrollo-software" },
  { name: "Páginas de Aterrizaje", icon: <MoveToInboxIcon sx={{ color: "#FF9800" }} />, ruta: "/servicios/desarrollo-software" },
  { name: "Aplicaciones Nativas", icon: <MobileFriendlyIcon sx={{ color: "#4CAF50" }} />, ruta: "/servicios/desarrollo-software" },
  { name: "Aplicaciones Híbridas", icon: <DeveloperModeIcon sx={{ color: "#FF5722" }} />, ruta: "/servicios/desarrollo-software" },
  { name: "CRM", icon: <ContactMailIcon sx={{ color: "#FFEB3B" }} />, ruta: "/servicios/desarrollo-software" },
  { name: "Desarrollo a Medida", icon: <CodeIcon sx={{ color: "#8BC34A" }} />, ruta: "/servicios/desarrollo-software" },
];

const SubmenuBlogServiciosMovile = () => {
  const navigate = useNavigate();

  const handleNavigation = (route) => {
    navigate(route);
  };

  return (
    <Box sx={{ backgroundColor: "#fff", borderRadius: "8px", width: "100%" }}>
      <Box sx={{ padding: "20px" }}>
        <Typography variant="h6" sx={{ fontWeight: 500, color: "#333", marginBottom: "16px" }}>
          Servicios
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "32px" }}>
          <Desplazable titulo="Servicios de Marketing" data={marketing} onItemClick={handleNavigation} />
          <Desplazable titulo="Servicios de Desarrollo de Software" data={desarrollo} onItemClick={handleNavigation} />
        </Box>
      </Box>
    </Box>
  );
};

export default SubmenuBlogServiciosMovile;
