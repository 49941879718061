import React from "react";
import { Box, Typography } from "@mui/material";

const EncabezadoIzquierdo = ({
  icon: IconComponent,
  iconText,
  title,
  subtitle,
  titleVariant = "h6",
  subtitleVariant = "body2",
  titleColor = "text.primary",
  subtitleColor = "text.secondary",
  backgroundColor = "#f4f4f4",
  iconColor = "#FFD700",
  iconSize = 30,
  ...rest
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        backgroundColor,
        borderRadius: 8,
      }}
      {...rest}
    >
      {/* Icono y texto al lado */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: 1,
        }}
      >
        {/* Renderiza directamente el ícono */}
        {IconComponent && (
          <IconComponent
            sx={{
              color: iconColor,
              fontSize: iconSize,
              marginRight: "8px", // Espaciado entre el ícono y el texto
            }}
          />
        )}

        <Typography
          variant="body2"
          sx={{
            color: "#F21C63",
            fontSize: "1rem",
            fontWeight: "600",
          }}
        >
          {iconText}
        </Typography>
      </Box>

      {/* Título y subtítulo */}
      <Box>
        <Typography
          variant={titleVariant}
          component="h2"
          sx={{
            color: titleColor,
            marginBottom: 2,
            fontSize: "2.5rem",
            fontWeight: "500",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant={subtitleVariant}
          sx={{
            color: subtitleColor,
            fontSize: "1.2rem",
            marginBottom: 4,
          }}
        >
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
};

export default EncabezadoIzquierdo;
