import CardSoluciones from "../components/cards/cardSoluciones/cardSoluciones";
import { Box, Grid } from "@mui/material";
import OfertaEspecial from "../components/texto/ofertaEspecial/ofertaEspecial";
import CardModernizacion from "../components/cards/cardModernizacion/cardModernizacion";
import BannerSoluciones from "./components/BannerSoluciones";
import SchoolIcon from "@mui/icons-material/School";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import CodeIcon from "@mui/icons-material/Code";
import BusinessIcon from "@mui/icons-material/Business";
import AutorenewIcon from "@mui/icons-material/Autorenew";

const Soluciones = () => {
  return (
    <>
      <Box>
        <BannerSoluciones
          title="Brindamos oportunidades de crecimiento en Internet,"
          TextColor="a profesionales, marcas y empresas."
          textBotton="Conoce más de nosotros."
          imgSrc="/images/soluciones.jpg"
        />
      </Box>

      {/* Sección de soluciones en formato Grid */}
      <Box
        id="seccio4"
        sx={{
          marginTop: "100px",
          padding: { xs: "0px 20px", md: "0px 150px" },
        }}
      >
        <Grid
          container
          spacing={3}
          justifyContent="center" // Centra horizontalmente
          alignItems="center" // Centra verticalmente
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            display="flex"
            justifyContent="center"
          >
            <CardSoluciones
              title="Profesionales"
              description="Adquiere nuevas habilidades y accede a oportunidades laborales en el mundo digital."
              features={[
                {
                  icon: <SchoolIcon sx={{ color: "#f21c63" }} />,
                  title: "Educación Especializada",
                  description:
                    "Cursos y certificaciones en marketing, tecnología y negocios digitales.",
                },
                {
                  icon: <WorkOutlineIcon sx={{ color: "#f21c63" }} />,
                  title: "Bolsa de Oportunidades",
                  description:
                    "Conéctate con empresas y accede a empleos digitales.",
                },
              ]}
              link={{
                text: "Ver formación",
                url: "#",
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            display="flex"
            justifyContent="center"
          >
            <CardSoluciones
              title="Emprendedores"
              description="Haz crecer tu negocio en internet con Inteliganecia artificial y marketing digital."
              features={[
                {
                  icon: <TrendingUpIcon sx={{ color: "#f21c63" }} />,
                  title: "Crecimiento Digital",
                  description:
                    "Estrategias para atraer clientes y monetizar tu proyecto.",
                },
                {
                  icon: <CodeIcon sx={{ color: "#f21c63" }} />,
                  title: "Presencia Online",
                  description:
                    "Webs, tiendas virtuales y automatización de ventas.",
                },
              ]}
              link={{
                text: "Impulsar mi emprendimiento",
                url: "#",
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            display="flex"
            justifyContent="center"
          >
            <CardSoluciones
              title="Marcas y Empresas"
              description="Escala tu negocio con transformación digital y automatización estratégica."
              features={[
                {
                  icon: <BusinessIcon sx={{ color: "#f21c63" }} />,
                  title: "Estrategia Digital",
                  description:
                    "Maximiza el posicionamiento y crecimiento de tu empresa.",
                },
                {
                  icon: <AutorenewIcon sx={{ color: "#f21c63" }} />,
                  title: "Automatización y Expansión",
                  description:
                    "Optimiza procesos y aumenta tu ROI con tecnología avanzada.",
                },
              ]}
              link={{
                text: "Escalar mi negocio",
                url: "#",
                color: "#0b8db5",
              }}
            />
          </Grid>
        </Grid>
      </Box>

      {/* Sección de Oferta Especial */}
      <Box
        sx={{
          margin: "5rem auto",
        }}
      >
        <OfertaEspecial />
      </Box>

      {/* Sección de Modernización */}
      <Box
        sx={{
          padding: {xs: "20px 20px", lg: "24px 250px"},
          margin: "5rem auto",
        }}
      >
        <CardModernizacion
          title="Soluciones Estratégicas para el Crecimiento y la Rentabilidad."
          description="Impulsamos el crecimiento de profesionales y empresas con estrategias digitales diseñadas para maximizar su potencial y generar resultados efectivos."
          items={[
            {
              image: "/images/setters.jpg",
              alt: "Setters",
              text: "Para Setters: Impulsa tu carrera con estrategias avanzadas, certificaciones y oportunidades laborales.",
              link: "/soluciones/proyecto-setters",
              buttonText: "Ver más",
            },
            {
              image: "/images/closers.jpg",
              alt: "Closers",
              text: "Para Closers: Encuentra oportunidades laborales con nuestra bolsa de trabajo y conéctate con marcas.",
              link: "/closers",
              buttonText: "Postúlate",
            },
            {
              image: "/images/marcasEmpresas.jpg",
              alt: "Marcas",
              text: "Para Marcas: Financia tus proyectos hasta en 4 cuotas sin intereses y capitaliza tu crecimiento.",
              link: "/soluciones/crecimiento-digital",
              buttonText: "Más info",
            },
            {
              image: "/images/trabajoDesarrollo.jpg",
              alt: "Bolsa de Trabajo",
              text: "Bolsa de trabajo para desarrolladores: Encuentra el equipo ideal para potenciar tus proyectos.",
              link: "/desarrolladores",
              buttonText: "Explorar",
            },
            {
              image: "/images/aliadosComerciales.jpg",
              alt: "Aliados Comerciales",
              text: "Aliados Comerciales: Colabora con nosotros, trae clientes o profesionales y crezcamos juntos.",
              link: "/aliados",
              buttonText: "Únete",
            },
          ]}
        />
      </Box>
    </>
  );
};

export default Soluciones;
