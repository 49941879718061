import React from "react";
import { Box, Grid, Typography, Link } from "@mui/material";
import {
  TrendingUp,
  InsertDriveFile,
  School,
  Star,
  Work,
} from "@mui/icons-material";
import BtnPrimary from "../../../components/botones/BtnPrimary";

// Constantes para los datos
const SOLUCIONES = {
  marcasYEmpresas: [
    {
      titulo: "Crecimiento Digital",
      subtitulo:
        "Impulsa tu marca a través de estrategias digitales que maximizan tu visibilidad y retorno de inversión.",
      icono: <TrendingUp sx={{ color: "#FF5722" }} />,
      link: "/soluciones/crecimiento-digital",
    },
    {
      titulo: "Herramientas Útiles",
      subtitulo:
        "Accede a herramientas digitales que optimizan tu productividad y mejoran la eficiencia en tu negocio.",
      icono: <InsertDriveFile sx={{ color: "#2196F3" }} />,
      link: "/soluciones/herramientas-utiles",
    },
  ],
  profesionales: [
    {
      titulo: "Cursos en Línea",
      subtitulo:
        "Accede a una amplia oferta de cursos diseñados para mejorar tus habilidades profesionales.",
      icono: <School sx={{ color: "#FFEB3B" }} />,
      link: "/soluciones/cursos-en-linea",
    },
    {
      titulo: "Recursos Destacados",
      subtitulo:
        "Obtén acceso a recursos clave que te ayudarán a avanzar en tu carrera y alcanzar tus objetivos.",
      icono: <Star sx={{ color: "#FF9800" }} />,
      link: "/soluciones/recursos-destacados",
    },
    {
      titulo: "Oportunidades",
      subtitulo:
        "Descubre oportunidades para crecer profesionalmente y colaborar con otros expertos del sector.",
      icono: <Work sx={{ color: "#9C27B0" }} />,
      link: "/soluciones/oportunidades",
    },
  ],
};

const NavegacionInternaSoluciones = () => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        minHeight: "auto",
        backgroundColor: "#fff",
      }}
    >
      {/* Hijo de 70% */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "65%",
          marginLeft: "10%",
          marginRight: "1%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between", // Separa los elementos
            width: "100%", // Asegura que ocupe todo el ancho disponible
            textAlign: "left",
            position: "relative",
            marginTop: "40px",
            marginBottom: "60px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between", // Separa los elementos
              alignItems: "center", // Alinea verticalmente
              width: "100%", // Asegura que ocupe todo el ancho disponible
            }}
          >
            {/* Título */}
            <Typography
              variant="h4"
              sx={{
                fontWeight: "400",
                fontSize: "1.1rem",
              }}
            >
              Soluciones
            </Typography>

            {/* Botón en el extremo derecho */}
            <BtnPrimary
              text="Todas las soluciones"
              to="/soluciones"
            />
          </Box>

          {/* Línea horizontal */}
          <Box
            sx={{
              width: "100%",
              height: "0.5px",
              backgroundColor: "#ddd",
              marginTop: "20px",
              marginBottom: "40px",
            }}
          ></Box>

          {/* Grid con columnas */}
          <Grid container spacing={2}>
            {/* Columna 1 */}
            <Grid item xs={4}>
              {/* Título */}
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "500",
                  mb: 1,
                  fontSize: "0.7rem",
                  cursor: "default",
                  color: "black",
                }}
              >
                PARA MARCAS Y PEQUEÑAS EMPRESAS
              </Typography>
              {/* Items */}
              {SOLUCIONES.marcasYEmpresas.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    cursor: "pointer",
                    mt: 3,
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* Icono */}
                  <Box sx={{ mr: 2 }}>{item.icono}</Box>
                  {/* Título y subtitulo */}
                  <Link
                    href={item.link}
                    sx={{
                      textDecoration: "none",
                      color: "black",
                      "&:hover": {
                        color: "#F21C63",
                      },
                    }}
                  >
                    <Box>
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "400",
                          mt: 1,
                          fontSize: "0.85rem",
                          width: "90%",
                          "&:hover": {
                            color: "#F21C63",
                          },
                        }}
                      >
                        {item.titulo}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          mb: 1,
                          fontWeight: "400",
                          mt: 1,
                          fontSize: "0.75rem",
                          width: "80%",
                        }}
                      >
                        {item.subtitulo}
                      </Typography>
                    </Box>
                  </Link>
                </Box>
              ))}
            </Grid>

            {/* Columna 2 */}
            <Grid item xs={4}>
              {/* Título */}
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "500",
                  mb: 1,
                  fontSize: "0.7rem",
                  cursor: "default",
                  color: "black",
                }}
              >
                PARA PROFESIONALES
              </Typography>
              {/* Items */}
              {SOLUCIONES.profesionales.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    mt: 3,
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* Icono */}
                  <Box sx={{ mr: 2 }}>{item.icono}</Box>
                  {/* Título y subtitulo */}
                  <Link
                    href={item.link}
                    sx={{
                      textDecoration: "none",
                      color: "black",
                      "&:hover": {
                        color: "#F21C63",
                      },
                    }}
                  >
                    <Box sx={{ cursor: "pointer" }}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "400",
                          mt: 1,
                          fontSize: "0.85rem",
                          width: "90%",
                          "&:hover": {
                            color: "#F21C63",
                          },
                        }}
                      >
                        {item.titulo}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          fontWeight: "400",
                          mt: 1,
                          fontSize: "0.75rem",
                          width: "80%",
                        }}
                      >
                        {item.subtitulo}
                      </Typography>
                    </Box>
                  </Link>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Hijo de 30% */}
      <Box
        sx={{
          backgroundColor: "#f4f4f4",
          width: "35%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "left",
          paddingLeft: "5%",
        }}
      >
        {/* Enlaces */}
        <Link
          href="#"
          sx={{
            color: "#211E26",
            textDecoration: "none",
            fontSize: "15px",
            fontWeight: "500",
            marginBottom: "10px",
            "&:hover": {
              color: "#F21C63",
            },
          }}
        >
          Noticias Importantes
        </Link>
        <Link
          href="#"
          sx={{
            color: "#211E26",
            textDecoration: "none",
            fontSize: "15px",
            marginBottom: "10px",
            fontWeight: "500",
            "&:hover": {
              color: "#F21C63",
            },
          }}
        >
          Recursos de Crecimiento
        </Link>
        <Link
          href="#"
          sx={{
            color: "#211E26",
            textDecoration: "none",
            fontSize: "15px",
            fontWeight: "500",
            marginBottom: "10px",
            "&:hover": {
              color: "#F21C63",
            },
          }}
        >
          Asociasiones tecnológicas
        </Link>
      </Box>
    </Box>
  );
};

export default NavegacionInternaSoluciones;
