// ProfileDetails.js
import React, { useState } from "react";
import { Box, Avatar, Typography, Paper } from "@mui/material";

const ProfileDetails = ({ title, items }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  if (!items || items.length === 0) return null;

  return (
    <Paper sx={{ textAlign: "center", mb: 2, p: 2 }}>
      <Typography variant="h6">{title}</Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: 2, mt: 2 }}>
        {items.map((item, index) => (
          <Box key={index} sx={{ textAlign: "center", position: "relative" }} onMouseEnter={() => setHoveredIndex(index)} onMouseLeave={() => setHoveredIndex(null)}>
            <Avatar
              src={item.logo || `https://www.google.com/s2/favicons?domain=${new URL(item.url).hostname}&sz=64`}
              alt={item.name}
            />
            {hoveredIndex === index && (
              <Typography variant="body2" sx={{ position: "absolute", top: "100%", left: "50%", transform: "translateX(-50%)", bgcolor: "rgba(0,0,0,0.7)", color: "white", borderRadius: 1, p: 0.5, mt: 1, whiteSpace: "nowrap" }}>
                {item.name}
              </Typography>
            )}
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

export default ProfileDetails;
