import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import settersData from "../profiles.json";
import { Box, Typography } from "@mui/material";

ChartJS.register(ArcElement, Tooltip, Legend);

const SettersStatus = () => {
  const [contratados, setContratados] = useState(0);
  const [disponibles, setDisponibles] = useState(0);

  useEffect(() => {
    let contratadosCount = 0;
    let disponiblesCount = 0;

    settersData.forEach((setter) => {
      if (setter.status === "Contratado") {
        contratadosCount++;
      } else if (setter.status === "Disponible") {
        disponiblesCount++;
      }
    });

    setContratados(contratadosCount);
    setDisponibles(disponiblesCount);
  }, []);

  const total = contratados + disponibles;
  const contratadosPercentage = total ? (contratados / total) * 100 : 0;
  const disponiblesPercentage = total ? (disponibles / total) * 100 : 0;

  const data = {
    labels: ["Contratados", "Disponibles"],
    datasets: [
      {
        data: [contratadosPercentage, disponiblesPercentage],
        backgroundColor: ["#6C4DE2", "#00C2FF"], // Violeta y celeste
        hoverBackgroundColor: ["#6C4DE2", "#00C2FF"],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "150px",
        boxSizing: "border-box",
        background: "#f6f8f8",
        color: "#333",
        borderRadius: "10px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      {/* Contenedor principal con estadísticas y gráfico */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "20px",
        }}
      >
        {/* Contenedor para las estadísticas a la izquierda */}
        <Box
          sx={{
            fontSize: "16px",
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            maxWidth: "250px",
          }}
        >
          <Typography variant="h6" sx={{ margin: 0, fontWeight: "bold" }}>
            Estado de la Bolsa
          </Typography>
          <Typography>
            Contratados: {contratados} ({contratadosPercentage.toFixed(2)}%)
          </Typography>
          <Typography>
            Disponibles: {disponibles} ({disponiblesPercentage.toFixed(2)}%)
          </Typography>
        </Box>

        {/* Gráfico de donut a la derecha */}
        <Box
          sx={{
            width: "120px",
            height: "120px",
            flexShrink: 0,
          }}
        >
          <Doughnut data={data} options={options} />
        </Box>
      </Box>
    </Box>
  );
};

export default SettersStatus;
