import { useState, useCallback } from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PreguntasFrecuentes = ({ questions = [] }) => {
  const [expanded, setExpanded] = useState(null);

  const handleChange = useCallback(
    (panel) => (_, isExpanded) => {
      setExpanded(isExpanded ? panel : null);
    },
    []
  );

  return (
    <Box sx={{ width: "100%" }}>
      {questions.map((question, index) => (
        <Accordion
          key={index}
          disableGutters
          elevation={0}
          square
          expanded={expanded === index} //
          onChange={handleChange(index)} //
          sx={{
            borderBottom: "1px solid #ddd",
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
            sx={{
              minHeight: "80px",
              "& .MuiAccordionSummary-content": {
                margin: "12px 0",
              },
            }}
          >
            <Typography variant="body1">{question.question}</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: "16px",
            }}
          >
            <Typography>{question.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default PreguntasFrecuentes;
