// VideoLayout.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ProgressBar from './components/progreso.jsx';
import VideoPlayer from './components/videoPlayer.jsx';
import VideoList from './components/VideoList.jsx';
import Footer from '../../../footer/page';
import ProgressAccordion from '../../../components/acordeones/ProgressAcordion';
import Resources from './components/resources.jsx';

const VideoLayout = ({ activities }) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(() => {
    const savedIndex = localStorage.getItem('currentVideoIndex');
    return savedIndex ? parseInt(savedIndex, 10) : 0;
  });
  const [completedVideos, setCompletedVideos] = useState(() => {
    const savedCompletedVideos = JSON.parse(localStorage.getItem('completedVideos'));
    return savedCompletedVideos || [];
  });

  useEffect(() => {
    localStorage.setItem('currentVideoIndex', currentVideoIndex);
  }, [currentVideoIndex]);

  useEffect(() => {
    if (completedVideos.length > 0) {
      localStorage.setItem('completedVideos', JSON.stringify(completedVideos));
    }
  }, [completedVideos]);

  const handleVideoChange = (index) => setCurrentVideoIndex(index);

  const handleVideoEnd = () => {
    setCompletedVideos((prev) => [...prev, currentVideoIndex]);
  };

  const toggleCompletedVideo = (index) => {
    setCompletedVideos((prev) =>
      prev.includes(index) ? prev.filter((videoIndex) => videoIndex !== index) : [...prev, index]
    );
  };

  const handleNextVideo = () => setCurrentVideoIndex((prev) => (prev + 1) % activities.length);
  const handlePreviousVideo = () => setCurrentVideoIndex((prev) => (prev - 1 + activities.length) % activities.length);

  return (
    <>
      {/* Barra de progreso */}
      <Box sx={{ padding: { xs: '20px', md: '0px 50px' }, marginTop: { xs: '20px', md: '70px' } }}>
        <ProgressBar completedVideos={completedVideos} totalVideos={activities.length} />
      </Box>

      {/* Contenedor principal con la disposición de video y lista de videos */}
      <Grid container spacing={2} sx={{ padding: { xs: '20px', md: '0px 50px' } }} direction={{ xs: 'column', md: 'row' }}>
        {/* Video (70% ancho) y lista de videos (30% ancho) */}
        <Grid item xs={12} md={8}>
          <Box sx={{ padding: '0px' }}>
            {/* Controles de navegación móvil */}
            <Box sx={{ display: { xs: 'flex', md: 'none' }, marginBottom: '30px', alignItems: 'center', justifyContent: 'space-between' }}>
              <Button variant="outlined" onClick={handlePreviousVideo} sx={{ backgroundColor: '#e0f7fa', borderRadius: '50%', padding: '10px', minWidth: '40px' }}>
                <ArrowBackIcon />
              </Button>
              <Typography variant="h6" sx={{ color: '#00695C', flexGrow: 1, textAlign: 'center' }}>
                {activities[currentVideoIndex]?.title}
              </Typography>
              <Button variant="outlined" onClick={handleNextVideo} sx={{ backgroundColor: '#e0f7fa', borderRadius: '50%', padding: '10px', minWidth: '40px' }}>
                <ArrowForwardIcon />
              </Button>
            </Box>

            {/* Reproductor de video */}
            <VideoPlayer videoUrl={activities[currentVideoIndex].videoUrl} onVideoEnd={handleVideoEnd} />
          </Box>
        </Grid>

        {/* Lista de videos (30% ancho) */}
        <Grid item xs={12} md={4}>
          <VideoList
            activities={activities}
            onSelectVideo={handleVideoChange}
            completedVideos={completedVideos}
            toggleCompletedVideo={toggleCompletedVideo}
            selectedVideoIndex={currentVideoIndex}
          />
        </Grid>
      </Grid>

      {/* Recursos debajo del video (70% ancho) */}
      <Grid container spacing={2} sx={{ padding: { xs: '20px', md: '0px 50px' }, marginTop: { xs: '0px', md: '40px' } }}>
        <Grid item xs={12} md={8}>
          <Resources resources={activities[currentVideoIndex]?.resources} />
        </Grid>

        {/* Acordeón de progreso (30% ancho) */}
        <Grid item xs={12} md={4}>
          <ProgressAccordion key={currentVideoIndex} panels={activities[currentVideoIndex]?.progressPanels || []} />
        </Grid>
      </Grid>

      <Box sx={{ marginTop: '40px' }}>
        <Footer />
      </Box>
    </>
  );
};

export default VideoLayout;
