// ProfileList.js
import React from "react";
import { Box, Paper, Typography, List, ListItem, ListItemAvatar, ListItemText, Avatar, useMediaQuery, useTheme } from "@mui/material";

const ProfileList = ({ profiles, onProfileSelect }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detectar si es un dispositivo móvil

  return (
    <Box sx={{ width: "100%", position: "relative" }}>
      <Paper sx={{ p: 2, maxHeight: "80vh", overflowY: "auto", bgcolor: "#F1F3F4", borderRadius: 2 }}>
        <Typography variant="h6" sx={{ mb: 2, textAlign: "center" }}>
          Selecciona un perfil
        </Typography>
        <List
          sx={{
            maxHeight: isMobile ? 500 : "50vh", // Se mantiene un alto fijo con scroll en escritorio
            overflowY: "auto",
            bgcolor: "#F1F3F4",
            borderRadius: 2
          }}
        >
          {profiles.map((profile) => (
            <ListItem
              key={profile.id}
              button
              onClick={() => onProfileSelect(profile)}
              sx={{
                bgcolor: "#fff",
                "&:hover": { bgcolor: "#f0f0f0" },
                borderRadius: 2,
                mb: 1
              }}
            >
              <ListItemAvatar>
                <Avatar src={profile.avatar} />
              </ListItemAvatar>
              <ListItemText
                primary={profile.name}
                secondary={
                  <Box>
                    <Typography variant="body2" color="textSecondary">
                      {profile.meetings} reuniones
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {profile.location}
                    </Typography>
                  </Box>
                }
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Box>
  );
};

export default ProfileList;
