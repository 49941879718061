import Box from "@mui/material/Box";
import CardPrecio from "../../../components/cards/cardPrecio/cardPrecio";
import Footer from "../../../footer/page";
import ProgressAccordion from "../../../components/acordeones/ProgressAcordion";
import ImagenPersonalizaReutilizable from "../../../components/imagenes/ImagenReutilizable/imagenPage";
import PriceBanner from "../../../components/banners/priceBanner/priceBanner";
import ServiceReviewsSetters from "../../components/ServiceReviewsSetters";
import TituloSubtituloCentro from "./../../../components/texto/TituloSubtituloCentro/cuerpoEnElCentro";

const Pricing = ({ pricingData }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          minHeight: "100vh",
        }}
      >
        <PriceBanner
          image={pricingData.imagen}
          mobileImage={pricingData.mobileImage}
          title={pricingData.titulo}
          description={pricingData.subtitulo}
          subtitle="Todos nuestros planes se enfocan 100% en tu capitalización digital. Con hitpoly el éxito esta asegurado."
        />

        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: 2,
              padding: { xs: "0px 20px", md: "10px 150px" },
            }}
          >
            {pricingData.planes.map((precio, index) => {
              console.log("Precio:", precio); // Depuración
              return (
                <Box
                  key={index}
                  sx={{
                    width: { xs: "100%", sm: "48%", md: "23%" },
                    marginBottom: 2,
                    borderRadius: 2,
                    border: "1px solid #0b8db5",
                  }}
                >
                  <CardPrecio
                    titulo={precio.titulo}
                    precioReal={precio.precioReal}
                    precioDescuento={precio.precioDescuento}
                    descripcion={precio.descripcion}
                    beneficios={precio.beneficios}
                    botontext={precio.botontext}
                    to={precio.to}
                  />
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: { xs: "20px", md: "50px" },
            padding: { xs: "0px 20px", md: "10px 150px" },
          }}
        >
          <ServiceReviewsSetters />
        </Box>
        <Box sx={{ padding: { xs: "40px 20px", md: "10px 150px" } }}>
          <TituloSubtituloCentro
            title="¡Importante antes de contratar!"
            subtitle="Nuestra promesa es asegurarte que cumpliremos fielmente con tus objetivos. Si lo que buscas es capitalizar en internet, te aseguramos que estás en buenas manos. Te brindamos una asesoría personalizada para que aclares todas tus dudas antes de comprar cualquiera de nuestros planes"
            titleColor="#f21c63"
          />
        </Box>
        <Box
          sx={{
            display: { md: "flex" },
            gap: "40px",
            marginTop: { xs: "10px", md: "80px" },
            marginBottom: "50px",
            width: "100%",
            padding: { xs: "0px 20px", md: "10px 150px" },
          }}
        >
          <Box sx={{ width: { xs: "100%", md: "50%" },}}>
            <ProgressAccordion panels={pricingData.acordeon} />
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "50%" },
              marginTop: { xs: "40px", md: "0px" },
            }}
          >
            <ImagenPersonalizaReutilizable images={pricingData.imagenes} />
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Pricing;
