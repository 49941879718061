import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import TituloSubtituloCentro from "../../texto/TituloSubtituloCentro/cuerpoEnElCentro";

const PriceBanner = ({ image, mobileImage, title, description, subtitle }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const backgroundImage = isMobile ? mobileImage : image; // Cambia la imagen según el tamaño de pantalla

  return (
    <Box>
      <Box
        sx={{
          borderRadius: "0 0 0 2.5rem",
          position: "relative",
          width: "100%",
          height: isMobile ? 350 : 450,
          backgroundImage: `url(${backgroundImage})`, // Usa la imagen condicional
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          color: "white",
          padding: isMobile ? 2 : 4,
          overflow: "hidden",
          "::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Oscurece el fondo
            zIndex: 1,
            WebkitMaskImage:
              "linear-gradient(to right, rgba(0,0,0,1) 20%, rgba(0,0,0,0) 100%)",
            maskImage:
              "linear-gradient(to right, rgba(0,0,0,1) 20%, rgba(0,0,0,0) 100%)",
          },

          "& > *": {
            position: "relative",
            zIndex: 2,
          },
        }}
      >
        <Box
          sx={{
            padding: isMobile ? 2 : 3,
            borderRadius: 2,
            maxWidth: { xs: "70%", md: "50%" },
            left: { xs: 10, md: 150 },
          }}
        >
          <Typography
            variant={isMobile ? "h6" : "h4"}
            fontWeight="bold"
            sx={{
              fontSize: { xs: "1.5rem", md: "2.5rem" },
              mb: 2,
              textAlign: "left",
            }}
          >
            {title}
          </Typography>
          <Typography variant={isMobile ? "body2" : "body1"} mt={1}>
            {description}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ width: "100%", padding: { xs: "50px 30px", md: "100px 10px" } }}>
        <TituloSubtituloCentro title={subtitle} />
      </Box>
    </Box>
  );
};

export default PriceBanner;
