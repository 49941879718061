// components/ServicioCard.js
import React from "react";
import { Box } from "@mui/material";
import EncabezadoIzquierdo from "../../../../components/texto/cuerpoIzquierdo/textoIzquierdo";
import ImagenPersonalizada from "../../../../components/imagenes/Imagen/imagenPage";
import SocialDistanceIcon from "@mui/icons-material/SocialDistance";
import AsesoriaPersonalizada from "../../../../components/botones/BtnPrimary";
import BtnSecondary from "../../../../components/botones/BtnSecondary";

const PortadaDeServicio = ({ title, subtitle, imgSrc, imgAlt, href, to }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      {/* Servicio 1 - EncabezadoIzquierdo */}
      <Box
        sx={{
          width: { xs: "100%", md: "50%" },
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Box sx={{ padding: {xs: "20px", md: "60px"}}}>
        <EncabezadoIzquierdo
          icon={SocialDistanceIcon}
          iconText="Social Media"
          title={title}
          subtitle={subtitle}
          backgroundColor="#ffffff"
          iconColor="#4285F4"
          iconSize={30}
        />
        <Box
          sx={{
            width: "80%",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "flex-start",
            gap: "16px",
          }}
        >
          <AsesoriaPersonalizada
            text="Contacta ahora mismo"
            backgroundColor="#F21C63"
            hoverColor="#bf0a46"
            fontSize="18px"
            fontWeight="600"
            to={href}
          />
          <BtnSecondary
            text="Agenda tu consulta"
            borderColor="#F21C63"
            hoverColor="#0B8DB5"
            fontSize="18px"
            fontWeight="600"
            to={to}
          />
        </Box>
        </Box>
      </Box>

      {/* Imagen - ImagenPersonalizada */}
      <Box sx={{width: { xs: "100%", md: "50%" },}}>
      <ImagenPersonalizada
        src={imgSrc}
        alt={imgAlt}
        width="100%"
        height="auto"
        borderRadius="0px"
        sx={{
          backgroundColor: "rgba(255,255,255",
          maxWidth: "550px",
          maxHeight: "400px",
          width: { xs: "100%", md: "100%" },
          display: { xs: "none", md: "flex" },
        }}
      />
      </Box>
    </Box>
  );
};

export default PortadaDeServicio;
