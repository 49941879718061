import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  useMediaQuery,
  IconButton,
  Collapse,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom"; // Importamos useLocation
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import BtnSecondary from "../../components/botones/BtnSecondary";
import AsesoriaPersonalizada from "../../components/botones/BtnPrimary";
import SubmenuBlogServiciosMovile from "./movil/servicios";
import SubmenuRecursosMovil from "./movil/recursos";
import SectoresYDepartamentos from "./movil/sectores";
import SubmenuBlogSolucionesMovile from "./movil/soluciones";

const MenuMobile = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [expanded, setExpanded] = useState(null);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const location = useLocation(); // Obtenemos la ubicación actual

  const menuItems = [
    { label: "Servicios", component: <SubmenuBlogServiciosMovile /> },
    { label: "Sectores", component: <SectoresYDepartamentos /> },
    { label: "Soluciones", component: <SubmenuBlogSolucionesMovile /> },
    { label: "Recursos", component: <SubmenuRecursosMovil /> },
  ];

  // Función para alternar el menú móvil
  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  // Cerrar el menú cuando la ruta cambie
  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location.pathname]); // Se ejecuta cada vez que cambia la ruta

  return (
    <Box>
      {/* Barra de navegación */}
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "65px",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#fff",
          padding: "0px 20px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          zIndex: 1000,
        }}
      >
        {isMobile && (
          <MenuIcon
            onClick={toggleMobileMenu}
            sx={{ fontSize: "25px", cursor: "pointer" }}
          />
        )}

        <Box
          component={Link}
          to="/"
          sx={{
            marginLeft: "15px",
            height: "40px",
            width: "auto",
            cursor: "pointer",
          }}
        >
          <Box
            component="img"
            src="/images/logo-hitpoly.png"
            alt="Logo"
            sx={{
              height: "40px",
              width: "auto",
            }}
          />
        </Box>

        {!isMobile && (
          <Box sx={{ display: "flex", gap: 2 }}>
            <BtnSecondary />
            <AsesoriaPersonalizada />
          </Box>
        )}
      </Box>

      {/* Menú móvil */}
      {isMobileMenuOpen && isMobile && (
        <Box
          sx={{
            position: "fixed",
            marginTop: "61px",
            top: 0,
            left: 0,
            width: "100%",
            height: "calc(100vh - 61px)",
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            zIndex: 9999,
            overflowY: "auto",
          }}
        >
          <IconButton
            onClick={toggleMobileMenu}
            sx={{
              position: "relative",
              alignSelf: "flex-end",
              color: "#211E26",
              fontSize: "30px",
              marginBottom: "20px",
              right: "5%",
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Contenedor desplazable para el contenido */}
          <Box
            sx={{
              width: "100%",
              overflowY: "auto",
              maxHeight: "calc(100vh - 100px)",
            }}
          >
            {menuItems.map((item, index) => (
              <Box
                key={index}
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <Box
                  sx={{
                    padding: "10px 20px",
                    borderBottom: "1px solid #eee",
                    cursor: "pointer",
                    backgroundColor: "#fff",
                    borderRadius: "5px",
                    textAlign: "center",
                    transition: "background-color 0.3s",
                    color: "#333",
                  }}
                  onClick={() => setExpanded(expanded === index ? null : index)}
                >
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {item.label}
                  </Typography>
                </Box>
                <Collapse in={expanded === index} timeout="auto" unmountOnExit>
                  <Box
                    sx={{
                      padding: "10px 10px",
                      backgroundColor: "#f5f5f5",
                      textAlign: "center",
                    }}
                  >
                    {item.component}
                  </Box>
                </Collapse>
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              marginTop: "20px",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* Botones que llevan a rutas */}
            <Box
              sx={{
                width: "100%",
                height: "40px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <AsesoriaPersonalizada to="/nuevo" />
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <BtnSecondary to="/contacto" />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MenuMobile;
