// ProfileLayout.js
import React, { useState } from "react";
import { Box, Card, CardContent, Avatar, Typography, Button, Grid, Modal, Paper, Divider, Chip } from "@mui/material";
import ProfileDetails from "./ProfileDetails";

const ProfileLayout = ({ profile }) => {
  const [open, setOpen] = useState(false);

  return (
    <Box>
      <Card
        sx={{
          width: "100%",
          margin: "auto",
          borderRadius: 3,
          overflow: "hidden",
          bgcolor: "#fff",
          p: 0,
        }}
      >
        <Box sx={{ position: "relative", height: 150, bgcolor: "#0B8DB5" }}>
          <Avatar
            src={profile.avatar}
            alt={profile.name}
            sx={{
              width: 100,
              height: 100,
              position: "absolute",
              bottom: -30,
              left: 20,
              border: "4px solid white",
            }}
          />
        </Box>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Typography variant="h6" fontWeight="bold" sx={{ mt: 4 }}>
                {profile.name}
              </Typography>
              <Typography color="text.secondary" variant="body2">
                {profile.bio}
              </Typography>
              <Typography color="text.secondary" variant="body2">
                {profile.location} •
                <Button
                  onClick={() => setOpen(true)}
                  sx={{
                    color: "#6C4DE2",
                    textTransform: "none",
                  }}
                >
                  Información de contacto
                </Button>
              </Typography>
              <Box sx={{ display: "flex", gap: 1, mt: 2, flexWrap: "wrap" }}>
                <Chip label={`Proyectos: ${profile.projects}`} sx={chipStyle("#E8F0FE", "#4285F4")} />
                <Chip label={`Experiencia: ${profile.experience} años`} sx={chipStyle("#F1F3F4", "#202124")} />
                <Chip label={`Idiomas: ${profile.languages.join(", ")}`} sx={chipStyle("#E8F0FE", "#4285F4")} />
                <Chip label={`Reuniones agendadas: ${profile.meetings}`} sx={chipStyle("#FBBC05", "#202124")} />
              </Box>

              <Box sx={{ mt: 2, p: 2, bgcolor: "#F1F3F4", borderRadius: 2 }}>
                <Typography variant="body1" fontWeight="bold">
                  Acerca de
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {profile.description || "Profesional con experiencia en diversas tecnologías y empresas destacadas."}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <ProfileDetails title="Empresas donde trabajó" items={profile.companies} />
              <ProfileDetails title="Herramientas utilizadas" items={profile.tools} />
              <ProfileDetails title="Academias" items={profile.academies} />
            </Grid>
          </Grid>
        </CardContent>
        <Modal open={open} onClose={() => setOpen(false)}>
          <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: 380, bgcolor: "#fff", borderRadius: 3, boxShadow: 24 }}>
            <Paper elevation={4} sx={{ p: 4, textAlign: "center" }}>
              <Typography variant="h5" fontWeight="bold" gutterBottom>
                Información de Contacto
              </Typography>
              <Divider sx={{ mb: 2 }} />
              {profile.email && (
                <Typography variant="body1" sx={{ mb: 1 }}>
                  📧 <strong>Email:</strong> {profile.email}
                </Typography>
              )}
              {profile.phone && (
                <Typography variant="body1" sx={{ mb: 1 }}>
                  📞 <strong>Teléfono:</strong> {profile.phone}
                </Typography>
              )}
              {profile.whatsapp && (
                <Button
                  variant="contained"
                  color="success"
                  sx={{ mt: 2, fontWeight: "bold" }}
                  fullWidth
                  href={`https://wa.me/${profile.whatsapp}`}
                  target="_blank"
                >
                  Contactar por WhatsApp
                </Button>
              )}
              <Button
                onClick={() => setOpen(false)}
                variant="outlined"
                sx={{ mt: 2, fontWeight: "bold", width: "100%", color: "#EA4335" }}
              >
                Cerrar
              </Button>
            </Paper>
          </Box>
        </Modal>
      </Card>
    </Box>
  );
};

const chipStyle = (bgColor, textColor) => ({
  bgcolor: bgColor,
  color: textColor,
  borderRadius: 2,
  fontWeight: "bold",
});

export default ProfileLayout;
