import React from "react";
import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Button
} from "@mui/material";
import { styled } from "@mui/system";
import TituloSubtituloCentro from "../texto/TituloSubtituloCentro/cuerpoEnElCentro";

const images = [
  {
    src: "/images/bannerHkRegistration.jpg",
    title: "HK Registration",
    description:
      "Servicios de registro y consultoría empresarial en Hong Kong.",
    link: "#",
  },
  {
    src: "/images/bannerCietePMarkting.jpg",
    title: "7P Marketing",
    description: "Agencia especializada en estrategias de marketing digital.",
    link: "#",
  },
  {
    src: "/images/bannerClinicaJacmont.jpg",
    title: "Clínica Jacmont",
    description:
      "Centro médico enfocado en tratamientos de rejuvenecimiento sin cirugía.",
    link: "#",
  },
  {
    src: "/images/bannerFuturaHomme.png",
    title: "Futura Homme",
    description:
      "Casa de artefactos tecnológicos de alta seguridad para casas inteligentes",
    link: "#",
  },
  {
    src: "/images/bannerHiHommeRd.jpg",
    title: "Hi Homme RD",
    description: "Inmobiliaria de casas de lujo en República Dominicana",
    link: "#",
  },
  {
    src: "/images/bannerLaboratoriosFarma.jpeg",
    title: "Laboratorios Farma",
    description:
      "Empresa farmacéutica dedicada a la investigación y desarrollo de medicamentos.",
    link: "#",
  },
  {
    src: "/images/bannerLisandroYAbogados.jpg",
    title: "Lisandro y Abogados",
    description: "Bufete de abogados especializados en derecho corporativo.",
    link: "#",
  },
  {
    src: "/images/bannerofisitca.jpeg",
    title: "Ofisitca",
    description: "Proveedor de soluciones tecnológicas para oficinas.",
    link: "#",
  },
  {
    src: "/images/bannerTigo.png",
    title: "Tigo",
    description:
      "Empresa de telecomunicaciones líder en servicios móviles e internet.",
    link: "#",
  },
  {
    src: "/images/bannerTotalDent.png",
    title: "Total Dent",
    description:
      "Clínica dental ofreciendo una amplia gama de servicios odontológicos.",
    link: "#",
  },
  {
    src: "/images/bannerArfasaAbogados.jpeg",
    title: "Arfasa Abogados",
    description: "Firma legal especializada en asesoría jurídica integral.",
    link: "#",
  },
  {
    src: "/images/bannerCbdVip.jpg",
    title: "CBD VIP",
    description: "Tienda premium de productos a base de CBD.",
    link: "#",
  },
];

const Overlay = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  color: "white",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  padding: "10px",
  boxSizing: "border-box",
  opacity: 0,
  transition: "opacity 0.3s ease-in-out",
}));

const CardContainer = styled(Card)({
  position: "relative",
  overflow: "hidden",
  width: "100%",
  "&:hover div": {
    opacity: 1,
  },
});

const CuadriculaPortfolio = () => {
  return (
    <>
      <Box
        spacing={2}
        padding={3}
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          maxWidth: "1200px",
          margin: "0 auto",
          marginBottom: "20px"
        }}
      >
        <TituloSubtituloCentro title="Marcas que pertenecen a nuestro portafolio: Nuestro Legado de Éxito" subtitle="Trabajamos con una amplia variedad de nichos. Cada cliente en nuestro portafolio es un reflejo de nuestra dedicación, innovación y compromiso con resultados extraordinarios."/>
      </Box>

      <Grid container spacing={2} padding={2} justifyContent="center">
        {images.map((image, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            key={index}
            display="flex"
            justifyContent="center"
          >
            <CardContainer>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="200"
                  image={image.src}
                  alt={image.title}
                />
                <Overlay>
                  <CardContent>
                    <Typography
                      variant="h6"
                      fontSize={{ xs: "1rem", sm: "1.2rem" }}
                    >
                      {image.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      gutterBottom
                      fontSize={{ xs: "0.8rem", sm: "1rem" }}
                    >
                      {image.description}
                    </Typography>
                  </CardContent>
                </Overlay>
              </CardActionArea>
            </CardContainer>
          </Grid>
        ))}
      </Grid>
      
      <Box display="flex" justifyContent="center" marginTop={4}>
        <Button
        sx={{background: "#0b8db5"}}
          variant="contained"
          onClick={() => window.location.href = "/portfolio"}
        >
          Ver más proyectos
        </Button>
      </Box>
    </>
  );
};

export default CuadriculaPortfolio;
