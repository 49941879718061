import React from "react";
import { Box } from "@mui/material";
import { motion } from "framer-motion";
import NavegationForSection from "../../../navbar/navSection/navbar.jsx";
import SectionVideo from "./secciones/sectionVideo.jsx";
import AcordeonInteligenteImagen from "./secciones/acordeonInteligenteImagen.jsx";
import SeccionCuerpoImagen from "./secciones/cuerpoConImagen.jsx";
import SeccionConTituloYAcordeon from "./secciones/preguntasYRespuestas.jsx";
import LlamadoALaAccion from "./secciones/llamadoALaAccionFinal.jsx";
import Footer from "../../../footer/page.jsx";
import TituloSubtituloCentro from "../../../components/texto/TituloSubtituloCentro/cuerpoEnElCentro.jsx";
import CuadriculaPortfolio from "../../../components/cuadroDePortafolio/cuadriculaDePortafolio.jsx";
import BannerSoluciones from "../../components/BannerSoluciones.jsx";
import BannerBuyerPerson from "../../../components/banners/clienteIdeal/bannerClienteIdeal.jsx";

const fadeInUp = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
};

const LayoutDynamicPage = ({
  portadaProps,
  menuItems,
  buyerPersonProps,
  tituloCentroProps,
  sectionVideoProps,
  acordeonInteligenteImagenProps,
  seccioneCuerpoImagenData,
  SeccionConTituloYAcordeonProps,
  llamadoAlaAccionProps,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        backgroundColor: "#fff",
        minHeight: "100vh",
        padding: 0,
      }}
    >
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={fadeInUp}
      >
        <Box sx={{ width: "100%" }}>
          <BannerSoluciones {...portadaProps} />
        </Box>
      </motion.div>

      <Box
        sx={{
          padding: "16px 24px",
          display: { xs: "none", md: "flex" },
          margin: "50px 0px",
          justifyContent: "center",
          alignItems: "center",
          position: "sticky",
          top: 80,
          zIndex: 100,
        }}
      >
        <NavegationForSection menuItems={menuItems} />
      </Box>
      <Box>
        <BannerBuyerPerson {...buyerPersonProps} />
      </Box>
      <Box>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={fadeInUp}
        >
          <Box
            id="seccio1"
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: { xs: "0px 20px", md: "50px 150px" },
              paddingTop: { xs: "50px", md: "80px" },
            }}
          >
            <SectionVideo {...sectionVideoProps} />
          </Box>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={fadeInUp}
        >
          <Box
            id="seccio2"
            sx={{
              backgroundColor: "#fff",
              padding: { xs: "0px 20px", md: "50px 150px" },
              paddingTop: { xs: "50px", md: "80px" },
            }}
          >
            <AcordeonInteligenteImagen {...acordeonInteligenteImagenProps} />
          </Box>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={fadeInUp}
        >
          <Box
            id="seccio4"
            sx={{
              backgroundColor: "#fff",
              paddingTop: { xs: "50px", md: "80px" },
            }}
          >
            <CuadriculaPortfolio />
          </Box>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={fadeInUp}
        >
          <Box
            id="seccio5"
            spacing={2}
            padding={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginTop: { xs: "10px", md: "80px" },
            }}
          >
            <TituloSubtituloCentro {...tituloCentroProps} />
          </Box>
        </motion.div>
        {seccioneCuerpoImagenData.map((seccion, index) => (
          <motion.div
            key={index}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={fadeInUp}
          >
            <Box
              id={`seccio5-${index}`}
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: { xs: "0px 20px", md: "50px 150px" },
                paddingTop: { xs: "50px", md: "50px" },
                gap: { xs: 0, md: 15 },
              }}
            >
              <SeccionCuerpoImagen data={{ ...seccion }} />
            </Box>
          </motion.div>
        ))}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={fadeInUp}
        >
          <Box
            id="seccio8"
            sx={{
              display: "flex",
              padding: { xs: "0px 20px", md: "50px 250px" },
              paddingTop: { xs: "50px", md: "100px" },
            }}
          >
            <SeccionConTituloYAcordeon {...SeccionConTituloYAcordeonProps} />
          </Box>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={fadeInUp}
        >
          <Box
            id="seccio9"
            sx={{
              display: "flex",
              padding: { xs: "0px 20px", md: "0px 150px" },
              paddingTop: { xs: "50px", md: "50px" },
              marginBottom: { xs: 5, md: 10 },
            }}
          >
            <LlamadoALaAccion {...llamadoAlaAccionProps} />
          </Box>
        </motion.div>
      </Box>
      <Footer />
    </Box>
  );
};

export default LayoutDynamicPage;
