import React from "react";
import { Box, Typography } from "@mui/material";
import DevicesIcon from "@mui/icons-material/Devices";
import BuildIcon from "@mui/icons-material/Build";
import SchoolIcon from "@mui/icons-material/School";
import WorkIcon from "@mui/icons-material/Work";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { useNavigate } from "react-router-dom";
import Desplazable from "./desplazable/elementoDesplazable";

const marcasYEmpresas = [
  { name: "Crecimiento Digital", icon: <DevicesIcon sx={{ color: "#00B0FF" }} />, ruta: "/soluciones/crecimiento-digital" },
  { name: "Herramientas útiles", icon: <BuildIcon sx={{ color: "#4CAF50" }} />, ruta: "/salud-y-bienestar" },
];

const profesionales = [
  { name: "Setter Digital", icon: <SchoolIcon sx={{ color: "#00B0FF" }} />, ruta: "/soluciones/proyecto-setters" },
  { name: "Recursos Destacados", icon: <WorkIcon sx={{ color: "#FF9800" }} />, ruta: "/ventas" },
  { name: "Oportunidades", icon: <EventAvailableIcon sx={{ color: "#4CAF50" }} />, ruta: "/recursos-humanos" },
];

const SubmenuBlogServiciosMovile = () => {
  const navigate = useNavigate();

  const handleNavigation = (route) => {
    navigate(route);
  };

  return (
    <Box sx={{ backgroundColor: "#fff", borderRadius: "8px", width: "100%" }}>
      <Box sx={{ padding: "20px" }}>
        <Typography variant="h6" sx={{ fontWeight: 500, color: "#333", marginBottom: "16px" }}>
          Soluciones
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "32px" }}>
          <Desplazable titulo="Para marcas y empresas" data={marcasYEmpresas} onItemClick={handleNavigation} />
          <Desplazable titulo="Para profesionales" data={profesionales} onItemClick={handleNavigation} />
        </Box>
      </Box>
    </Box>
  );
};

export default SubmenuBlogServiciosMovile;