import React from "react";
import { Box, Grid, Typography, Link } from "@mui/material";
import {
  Group,
  AddShoppingCart,
  Email,
  InsertChart,
  Code,
  Business,
} from "@mui/icons-material"; // Importar los iconos
import BtnPrimary from "../../../components/botones/BtnPrimary";
// Constantes para los datos
const SERVICIOS = {
  pequeñasEmpresas: [
    {
      titulo: "Redes Sociales",
      subtitulo: "Alcanza grandes audiencias mediante contenido atractivo",
      icono: <Group sx={{ color: "#1877F2" }} />,
      link: "/servicios/redes-sociales", // URL de la página del servicio
    },
    {
      titulo: "Publicidad Digital",
      subtitulo:
        "Consigue tráfico cualificado, leads, clientes potenciales, ventas, etc",
      icono: <AddShoppingCart sx={{ color: "#F21C63" }} />,
      link: "/servicios/publicidad-digital", // URL de la página del servicio
    },
    {
      titulo: "Email Marketing",
      subtitulo:
        "Aumenta el retorno de tu inversión con estrategias de lead nurturing",
      icono: <Email sx={{ color: "#FF5722" }} />,
      link: "/servicios/email-marketing", // URL de la página del servicio
    },
  ],
  grandesEmpresas: [
    {
      titulo: "Inbound Marketing",
      subtitulo:
        "Mejora el ROAS con estrategias personalizadas de inbound marketing",
      icono: <InsertChart sx={{ color: "#4CAF50" }} />,
      link: "/servicios/inbound-marketing", // URL de la página del servicio
    },
    {
      titulo: "Asesoría de Inbound Marketing",
      subtitulo:
        "Asesoramos a tus equipos y los adaptamos a la metodología inbound",
      icono: <Business sx={{ color: "#9C27B0" }} />,
      link: "/servicios/asesoria-inbound", // URL de la página del servicio
    },
  ],
  solucionesAvanzadas: [
    {
      titulo: "Desarrollo de Software",
      subtitulo:
        "Construimos sistemas SaaS, IaaS y PaaS fáciles de adoptar y desplegar",
      icono: <Code sx={{ color: "#2196F3" }} />,
      link: "/servicios/desarrollo-software", // URL de la página del servicio
    },
  ],
};

const NavegacionInternaServicios = () => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        minHeight: "auto",
        backgroundColor: "#fff",
      }}
    >
      {/* Hijo de 70% */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "65%",
          marginLeft: "10%",
          marginRight: "1%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "left",
            position: "relative",
            marginTop: "40px",
            marginBottom: "60px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between", // Separa los elementos
              alignItems: "center", // Alinea verticalmente
              width: "100%", // Asegura que ocupe todo el ancho disponible
            }}
          >
            {/* Título */}
            <Typography
              variant="h4"
              sx={{
                fontWeight: "400",
                fontSize: "1.1rem",
              }}
            >
              Soluciones
            </Typography>

            {/* Botón en el extremo derecho */}
            <BtnPrimary text="Todos los servicios" to="/servicios" />
          </Box>

          {/* Línea horizontal */}
          <Box
            sx={{
              width: "100%",
              height: "0.5px",
              backgroundColor: "#ddd",
              marginTop: "20px",
              marginBottom: "40px",
            }}
          ></Box>

          {/* Grid con columnas */}
          <Grid container spacing={2}>
            {/* Columna 1 */}
            <Grid item xs={4}>
              {/* Título */}
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "500",
                  mb: 1,
                  fontSize: "0.7rem",
                  cursor: "default",
                  color: "black",
                }}
              >
                PARA MARCAS Y PEQUEÑAS EMPRESAS
              </Typography>
              {/* Items */}
              {SERVICIOS.pequeñasEmpresas.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    cursor: "pointer",
                    mt: 3,
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* Icono */}
                  <Box sx={{ mr: 2 }}>{item.icono}</Box>
                  {/* Título y subtitulo */}
                  <Link
                    href={item.link}
                    sx={{
                      textDecoration: "none",
                      color: "black",
                      "&:hover": {
                        color: "#F21C63",
                      },
                    }}
                  >
                    <Box>
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "400",
                          mt: 1,
                          fontSize: "0.85rem",
                          width: "90%",
                        }}
                      >
                        {item.titulo}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          mb: 1,
                          fontWeight: "400",
                          mt: 1,
                          fontSize: "0.75rem",
                          width: "80%",
                        }}
                      >
                        {item.subtitulo}
                      </Typography>
                    </Box>
                  </Link>
                </Box>
              ))}
            </Grid>

            {/* Columna 2 */}
            <Grid item xs={4}>
              {/* Título */}
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "500",
                  mb: 1,
                  fontSize: "0.7rem",
                  cursor: "default",
                  color: "black",
                }}
              >
                PARA GRANDES EMPRESAS
              </Typography>
              {/* Items */}
              {SERVICIOS.grandesEmpresas.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    mt: 3,
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* Icono */}
                  <Box sx={{ mr: 2 }}>{item.icono}</Box>
                  {/* Título y subtitulo */}
                  <Link
                    href={item.link}
                    sx={{
                      textDecoration: "none",
                      color: "black",
                      "&:hover": {
                        color: "#F21C63",
                      },
                    }}
                  >
                    <Box>
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "400",
                          mt: 1,
                          fontSize: "0.85rem",
                          width: "90%",
                        }}
                      >
                        {item.titulo}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          fontWeight: "400",
                          mt: 1,
                          fontSize: "0.75rem",
                          width: "80%",
                        }}
                      >
                        {item.subtitulo}
                      </Typography>
                    </Box>
                  </Link>
                </Box>
              ))}
            </Grid>

            {/* Columna 3 */}
            <Grid item xs={4}>
              {/* Título */}
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "500",
                  mb: 1,
                  fontSize: "0.7rem",
                  cursor: "default",
                  color: "black",
                }}
              >
                SOLUCIONES AVANZADAS
              </Typography>
              {/* Items */}
              {SERVICIOS.solucionesAvanzadas.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    mt: 3,
                    mb: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* Icono */}
                  <Box sx={{ mr: 2 }}>{item.icono}</Box>
                  {/* Título y subtitulo */}
                  <Link
                    href={item.link}
                    sx={{
                      textDecoration: "none",
                      color: "black",
                      "&:hover": {
                        color: "#F21C63",
                      },
                    }}
                  >
                    <Box>
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "400",
                          mt: 1,
                          fontSize: "0.85rem",
                          width: "90%",
                        }}
                      >
                        {item.titulo}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          fontWeight: "400",
                          mt: 1,
                          fontSize: "0.75rem",
                          width: "80%",
                        }}
                      >
                        {item.subtitulo}
                      </Typography>
                    </Box>
                  </Link>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Hijo de 30% */}
      <Box
        sx={{
          backgroundColor: "#f4f4f4",
          width: "35%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "left",
          paddingLeft: "5%",
        }}
      >
        {/* Enlaces */}
        <Link
          href="#"
          sx={{
            color: "#211E26",
            textDecoration: "none",
            fontSize: "15px",
            fontWeight: "500",
            marginBottom: "10px",
            "&:hover": {
              color: "#F21C63",
            },
          }}
        >
          Noticias Importantes
        </Link>
        <Link
          href="#"
          sx={{
            color: "#211E26",
            textDecoration: "none",
            fontSize: "15px",
            marginBottom: "10px",
            fontWeight: "500",
            "&:hover": {
              color: "#F21C63",
            },
          }}
        >
          Beneficios para las empresas
        </Link>
      </Box>
    </Box>
  );
};

export default NavegacionInternaServicios;
