import React from "react";
import PageBanner from "../components/banners/BannerPage/pageBanner";
import ServiceList from "./components/SectionCards";
import Footer from "../footer/page";
import MarqueeBanner from "../components/banners/cartelSlider";
import { Box } from "@mui/material";
import ProgressAccordion from "../components/acordeones/ProgressAcordion";
import ConjuntoDeTextoSection from "../components/texto/cuerpoDeSecciones/conjuntoDeTexto";
import ImagenPersonalizada from "../components/imagenes/Imagen/imagenPage";

const Servicios = () => {
  const panelsData = [
    {
      id: "panel1",
      title: "Financiamiento flexible",
      content:
        "Financiamos tu proyecto hasta en cuatro cuotas sin intereses adicionales. Nuestro objetivo es facilitar el crecimiento de tu negocio sin que la inversión sea una barrera. Con opciones de pago accesibles, puedes iniciar tu estrategia digital sin preocuparte por el presupuesto.",
    },
    {
      id: "panel2",
      title: "Equipo de primer nivel",
      content:
        "Nuestro equipo está conformado por profesionales con amplia experiencia en marketing digital y crecimiento empresarial. A lo largo de nuestra trayectoria, hemos evaluado y seleccionado expertos que han demostrado resultados sólidos en capitalización digital.",
    },
    {
      id: "panel3",
      title: "Capitaliza con nosotros",
      content:
        "Te ayudamos a transformar tu inversión en resultados tangibles. Implementamos estrategias probadas que optimizan cada aspecto de tu negocio digital. Desde el branding hasta la automatización, garantizamos que cada paso esté enfocado en la conversión y el crecimiento sostenible.",
    },
    {
      id: "panel4",
      title: "Estrategia integral para tu marca",
      content:
        "No solo creamos tu marca, sino que la potenciamos con una gestión de contenido estratégica, campañas publicitarias inteligentes y automatización de procesos. Nos encargamos de todo el ecosistema digital para que puedas centrarte en lo más importante: hacer crecer tu negocio sin preocupaciones.",
    },
  ];

  return (
    <Box>
      <PageBanner
        image="images/bannerPages.png"
        title="TE AYUDAMOS A INTERNACIONALIZAR TU MARCA, EN MENOS DE 3 MESES."
        description="SOMOS ESPECIALISTAS EN MARKETING INTERNACIONAL Y ESTRATEGIAS DIGITALES"
      />
      <Box>
        <MarqueeBanner
          texts={[
            "Financia tu proyecto hasta en 4 cuotas, sin intereses",
            "Reclama descuentos irresistibles por cada servicio",
            "Capitaliza tu negocio en INTERNET de forma 100% segura",
          ]}
        />
      </Box>
      <Box
        sx={{
          padding: {xs: "20px 10px", md: "20px 100px"},
          display: "flex",
          flexDirection: { xs: "column-reverse", lg: "row" },
          justifyContent: "center",
          alignItems: "center",
          gap: 5,
          width: "100%",
          marginTop: "50px",
        }}
      >
        <Box sx={{ width: { xs: "100%", lg: "50%" }, }}>
          <ImagenPersonalizada src="images/CampanasResultados.jpg" />
        </Box>
        <Box
          sx={{
            width: { xs: "100%", lg: "50%" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center", 
            height: "100%",
          }}
        >
          <ConjuntoDeTextoSection
            title="Capitaliza tu marca en Internet de forma 100% segura."
            subtitle="En Hitpoly, financiamos tu proyecto hasta en cuatro cuotas y contamos con un equipo de profesionales evaluados a lo largo de nuestra trayectoria, garantizando resultados en la capitalización digital de nuestros clientes. Creamos tu marca, gestionamos tu contenido, lanzamos campañas publicitarias y automatizamos procesos para maximizar tu rentabilidad."
            linkUrl="/portfolio"
            linkText=" Conoce nuestros casos de éxito"
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 2,
          justifyContent: "center",
          alignItems: "center", // Centra verticalmente en pantallas grandes
          width: "100%",
          padding: { xs: "20px", md: "20px 100px" },
        }}
      >
        {/* Contenedor del acordeón con centramiento vertical */}
        <Box
          sx={{
            width: { xs: "100%", md: "50%", xl: "40%" },
            display: "flex",
            alignItems: "center", // Centra verticalmente el acordeón
          }}
        >
          <ProgressAccordion panels={panelsData} />
        </Box>
        <Box sx={{ width: { xs: "100%", md: "50%", xl: "60%" } }}>
          <ServiceList />
        </Box>
      </Box>

      <Footer />
    </Box>
  );
};

export default Servicios;
