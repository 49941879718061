import React from "react";
import { Box, Typography } from "@mui/material";
import { styled, keyframes } from "@mui/system";
import TypographyColumns from "../../components/cabeceras/vertical/verticalUno/VerticalUno";

const typographyData = [
  {
    variant: "body1",
    color: "#211E26",
    textTransform: "uppercase",
    fontSize: { xs: "16px", sm: "18px", md: "18px", lg: "18px" }, // Ajuste responsivo de tamaño
    maxWidth: "40%",
    text: "ALGUNAS RESEÑAS",
    fontWeight: 500,
  },
  {
    variant: "h5",
    color: "#211E26",
    fontSize: "2.5rem",
    fontWeight: "500",
    maxWidth: { xs: "80%", md: "60%" },
    text: "Opiniones de nuestros clientes",
  },
];
// Datos de las reseñas actualizados
const row1 = [
  {
    title: "Citas Consistentes",
    rating: "★★★★★",
    paragraph:
      "Con el apoyo continuo de Hitpoly, ahora genero citas de calidad todos los días sin esfuerzo.",
  },
  {
    title: "Cierre Asegurado",
    rating: "★★★★★",
    paragraph:
      "Gracias al acompañamiento de expertos, agendo prospectos listos para cerrar con confianza.",
  },
  {
    title: "Mayor Conversión",
    rating: "★★★★☆",
    paragraph:
      "Nuestro equipo me guió para convertir citas en ventas de alto valor de manera consistente.",
  },
  {
    title: "Resultados en Equipo",
    rating: "★★★★★",
    paragraph:
      "Con el respaldo de la comunidad Hitpoly, logré aumentar mis comisiones y clientes calificados.",
  },
  {
    title: "Prospectos Ideales",
    rating: "★★★★☆",
    paragraph:
      "El trabajo colaborativo me ayudó a filtrar y atraer clientes realmente interesados.",
  },
  {
    title: "Productividad x2",
    rating: "★★★★★",
    paragraph:
      "El acompañamiento constante mejoró mi rendimiento y duplicó mis resultados.",
  },
];

const row2 = [
  {
    title: "Estrategias en Equipo",
    rating: "★★★★★",
    paragraph:
      "Hitpoly me brindó un sistema infalible y un equipo de apoyo para agendar sin perder tiempo.",
  },
  {
    title: "Citas de Calidad",
    rating: "★★★★☆",
    paragraph:
      "Gracias al seguimiento personalizado, cada prospecto que agendo es alguien realmente interesado.",
  },
  {
    title: "Más Cierres, Menos Estrés",
    rating: "★★★★★",
    paragraph:
      "Con el respaldo del equipo, las citas que programo ahora tienen un alto porcentaje de conversión.",
  },
  {
    title: "Prospección Guiada",
    rating: "★★★★☆",
    paragraph:
      "Con la orientación de expertos, dejé de depender de referidos y ahora tengo un flujo constante de clientes.",
  },
  {
    title: "Comisiones Maximizadas",
    rating: "★★★★★",
    paragraph:
      "Mis ingresos subieron drásticamente gracias a la mentoría y estrategias probadas de Hitpoly.",
  },
  {
    title: "Siempre Acompañado",
    rating: "★★★★★",
    paragraph:
      "Nunca estoy solo en el proceso, el equipo de Hitpoly siempre está ahí para apoyarme.",
  },
];



const scrollX = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

// Estilos usando Material-UI
const AppContainer = styled(Box)`
  display: flex;
  margin-top: 20px;
  overflow: hidden;
  max-width: 100vw;
  background-color: #ffffff; /* Fondo blanco */
`;

const Wrapper = styled(Box)`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  max-width: 100vw;
  background-color: #ffffff; /* Fondo blanco */
`;

const Marquee = styled(Box)`
  display: flex;
  width: 1700px;
  overflow: hidden;
  user-select: none;
  position: relative;
  mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 10%,
    hsl(0 0% 0% / 1) 90%,
    hsl(0 0% 0% / 0)
  );
`;

const MarqueeGroup = styled(Box)`
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  width: 100%;
  animation: ${scrollX} 50s linear infinite;
  flex-shrink: 0;
  overflow: hidden;
`;

const MarqueeGroup2 = styled(Box)`
  margin-top: 50px;
  overflow: hidden;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  width: 100%;
  animation: ${scrollX} 50s linear infinite;
  animation-direction: reverse;
  animation-delay: -3s;
  flex-shrink: 0;
`;

const CajaReviw = styled(Box)`
  position: relative; /* Necesario para posicionar los pseudo-elementos */
  margin-left: 30px;
  margin-right: 30px; /* Añadido margen derecho */
  margin-bottom: 20px; /* Añadido margen inferior */
  overflow: hidden;
  max-width: 300px; /* Limita el ancho para evitar el desbordamiento */
  padding: 12px;
  height: 150px;
  background: #ffffff; /* Fondo blanco */
  border-radius: 0.5rem;
  color: #333333; /* Color de texto oscuro para contraste */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
  overflow-wrap: break-word;
  word-wrap: break-word;
  text-overflow: ellipsis;
  white-space: normal;

  /* Pseudo-elementos para el degradado */
  &::before,
  &::after {
    content: "";
    position: absolute;
    border-radius: 50%;
    background: radial-gradient(
      circle,
      rgba(115, 17, 187, 0.5),
      transparent 60%
    );
    transition: background 0.3s;
    z-index: -1; /* Hace que los pseudo-elementos queden detrás del contenido */
  }

  &::before {
    top: 30px;
    left: -80px;
    width: 200px;
    height: 200px;
    filter: blur(30px);
  }

  &::after {
    bottom: 30px;
    right: -80px;
    width: 200px;
    height: 200px;
    filter: blur(30px);
  }

  /* Efecto en hover (opcional) */
  &:hover::before,
  &:hover::after {
    background: radial-gradient(
      circle,
      rgba(115, 17, 187, 0.8),
      transparent 60%
    );
  }
`;

// Componente que mostrará los datos de las reseñas
const Review = ({ title, rating, paragraph }) => {
  return (
    <CajaReviw>
      <Typography
        variant="h6"
        sx={{
          fontWeight: "400",
          marginBottom: "5px",
          fontSize: "1rem",
        }}
      >
        {title}
      </Typography>
      <Typography variant="body1" sx={{ color: "gold", marginBottom: "5px" }}>
        {rating}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          marginBottom: "5px",
          fontWeight: "300",
          fontSize: ".8rem",
          whiteSpace:
            "normal" /* Asegura que el texto pueda romperse en múltiples líneas */,
          overflowWrap:
            "break-word" /* Asegura que las palabras largas se quiebren */,
        }}
      >
        {paragraph}
      </Typography>
    </CajaReviw>
  );
};

const ServiceReviewsSetters = () => {
  return (
    <AppContainer
      sx={{
        width: "100%",
        color: "#333333" /* Color de texto general */,
        fontWeight: "bold",
      }}
    >
      <Wrapper
        sx={{
          color: "#333333" /* Color de texto general */,
          fontWeight: "bold",
          width: "100%",
        }}
      >
        <TypographyColumns data={typographyData} />
        <Marquee>
          <MarqueeGroup>
            {row1.map((el, index) => (
              <Review
                key={index}
                title={el.title}
                rating={el.rating}
                paragraph={el.paragraph}
              />
            ))}
          </MarqueeGroup>
          <MarqueeGroup>
            {row1.map((el, index) => (
              <Review
                key={index}
                title={el.title}
                rating={el.rating}
                paragraph={el.paragraph}
              />
            ))}
          </MarqueeGroup>
        </Marquee>
        <Marquee>
          <MarqueeGroup2>
            {row2.map((el, index) => (
              <Review
                key={index}
                title={el.title}
                rating={el.rating}
                paragraph={el.paragraph}
              />
            ))}
          </MarqueeGroup2>
          <MarqueeGroup2>
            {row2.map((el, index) => (
              <Review
                key={index}
                title={el.title}
                rating={el.rating}
                paragraph={el.paragraph}
              />
            ))}
          </MarqueeGroup2>
        </Marquee>
      </Wrapper>
    </AppContainer>
  );
};

export default ServiceReviewsSetters;
