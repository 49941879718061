import React, { useState } from "react";
import VideoLayout from "./layout"; // Asumimos que este es el layout del video
import { Box } from "@mui/material";

// Actividades con los videos
const activities = [
  {
    title: "Video 1 - Introducción al Programa",
    videoUrl: "https://www.youtube.com/embed/bbbqxb_PUz0?autoplay=1",
    resources: [
      { name: "Guía PDF", description: "Descarga la guía de introducción", url: "/guide1.pdf" },
      { name: "Artículo", description: "Lee el artículo de bienvenida", url: "/article1.pdf" },
    ],
    progressPanels: [
      { id: "panel1", title: "Concepto Clave", content: "Explicación del programa Setters de Elite" },
      { id: "pane2", title: "Objetivos", content: "Metas y objetivos del programa" },
    ],
  },
  {
    title: "Video 2 - Desarrollo de Habilidades",
    videoUrl: "https://www.youtube.com/embed/YD9UqFZt3bA?autoplay=1",
    resources: [
      { name: "Guía PDF", description: "Desarrollo de habilidades clave", url: "/guide2.pdf" },
      { name: "Video", description: "Ver el video sobre técnicas avanzadas", url: "/advanced-video.mp4" },
    ],
    progressPanels: [
      { id: "panel1", title: "Técnicas Avanzadas", content: "Cómo mejorar tus habilidades en ventas" },
      { id: "pane2", title: "Práctica", content: "Ejercicios para aplicar las técnicas" },
    ],
  },
  {
    title: "Video 3 - Estrategias de Comunicación",
    videoUrl: "https://www.youtube.com/embed/TUdm25vF3P8?autoplay=1",
    resources: [
      { name: "PDF", description: "Mejora tu comunicación efectiva", url: "/communication-guide.pdf" },
      { name: "Webinar", description: "Accede al webinar sobre estrategias de comunicación", url: "/webinar1.mp4" },
    ],
    progressPanels: [
      { id: "panel1", title: "Estrategias Efectivas", content: "Técnicas para comunicarte de manera efectiva" },
      { id: "pane2", title: "Aplicación Práctica", content: "Cómo aplicar lo aprendido en situaciones reales" },
    ],
  },
  {
    title: "Video 4 - Manejo de Objeciones",
    videoUrl: "https://www.youtube.com/embed/BzK9N1B1LGA?autoplay=1",
    resources: [
      { name: "Guía PDF", description: "Cómo manejar objeciones con éxito", url: "/objection-guide.pdf" },
      { name: "Ejercicio", description: "Práctica de manejo de objeciones", url: "/objection-exercise.pdf" },
    ],
    progressPanels: [
      { id: "panel1", title: "Superando Objeciones", content: "Técnicas para responder a objeciones comunes" },
      { id: "pane2", title: "Role Play", content: "Simulación de situaciones con objeciones" },
    ],
  },
  {
    title: "Video 5 - Cierre de Ventas",
    videoUrl: "https://www.youtube.com/embed/2sZYfnw6D8E?autoplay=1",
    resources: [
      { name: "Guía PDF", description: "Estrategias para cerrar ventas efectivas", url: "/closing-guide.pdf" },
      { name: "Plantilla", description: "Plantilla de cierre de ventas", url: "/closing-template.pdf" },
    ],
    progressPanels: [
      { id: "panel1", title: "Estrategias de Cierre", content: "Técnicas para cerrar ventas con éxito" },
      { id: "pane2", title: "Práctica", content: "Aplicación de estrategias de cierre" },
    ],
  },
  {
    title: "Video 6 - Psicología del Comprador",
    videoUrl: "https://www.youtube.com/embed/j-0fEF7-fD8?autoplay=1",
    resources: [
      { name: "Guía PDF", description: "Entiende la psicología detrás de una compra", url: "/buyer-psychology-guide.pdf" },
      { name: "Estudio de Caso", description: "Estudio de caso sobre la psicología del comprador", url: "/case-study.pdf" },
    ],
    progressPanels: [
      { id: "panel1", title: "Comportamiento del Comprador", content: "Factores que influyen en las decisiones de compra" },
      { id: "pane2", title: "Técnicas Psicológicas", content: "Uso de técnicas psicológicas para influir en la compra" },
    ],
  },
  {
    title: "Video 7 - Estrategias de Marketing Digital",
    videoUrl: "https://www.youtube.com/embed/3mTUtQxmT7A?autoplay=1",
    resources: [
      { name: "Guía PDF", description: "Introducción al marketing digital", url: "/digital-marketing-guide.pdf" },
      { name: "Ebook", description: "Ebook sobre estrategias digitales", url: "/digital-marketing-ebook.pdf" },
    ],
    progressPanels: [
      { id: "panel1", title: "Canales Digitales", content: "Cómo aprovechar los canales digitales para ventas" },
      { id: "pane2", title: "Estrategias de Publicidad", content: "Estrategias de marketing en redes sociales" },
    ],
  },
  {
    title: "Video 8 - Networking y Creación de Redes",
    videoUrl: "https://www.youtube.com/embed/8l_ZkdWj9TA?autoplay=1",
    resources: [
      { name: "Guía PDF", description: "Construye tu red de contactos", url: "/networking-guide.pdf" },
      { name: "Video", description: "Ver el video sobre cómo hacer networking efectivo", url: "/networking-video.mp4" },
    ],
    progressPanels: [
      { id: "panel1", title: "Networking Efectivo", content: "Cómo construir relaciones de valor en tu industria" },
      { id: "pane2", title: "Herramientas para Networking", content: "Mejores herramientas para hacer networking" },
    ],
  },
  {
    title: "Video 9 - Liderazgo en Ventas",
    videoUrl: "https://www.youtube.com/embed/sy8U7TcExho?autoplay=1",
    resources: [
      { name: "Guía PDF", description: "Desarrolla habilidades de liderazgo", url: "/leadership-guide.pdf" },
      { name: "Seminario", description: "Asiste al seminario sobre liderazgo en ventas", url: "/leadership-seminar.mp4" },
    ],
    progressPanels: [
      { id: "panel1", title: "Liderazgo en Ventas", content: "Cómo liderar equipos de ventas exitosos" },
      { id: "pane2", title: "Gestión de Equipos", content: "Técnicas para la gestión de equipos de alto rendimiento" },
    ],
  },
  {
    title: "Video 10 - Evaluación y Feedback",
    videoUrl: "https://www.youtube.com/embed/7Im96_KvZs0?autoplay=1",
    resources: [
      { name: "Guía PDF", description: "Cómo dar y recibir feedback efectivo", url: "/feedback-guide.pdf" },
      { name: "Encuesta", description: "Encuesta de retroalimentación", url: "/feedback-survey.pdf" },
    ],
    progressPanels: [
      { id: "panel1", title: "Importancia del Feedback", content: "La importancia del feedback en la mejora continua" },
      { id: "pane2", title: "Práctica", content: "Cómo dar y recibir retroalimentación efectiva" },
    ],
  },
];

const PasosIniciales = () => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  return (
    <Box>
      {/* Mostrar solo el video actual */}
      <VideoLayout
        videoUrl={activities[currentVideoIndex].videoUrl}
        titulo={activities[currentVideoIndex].title}
        resources={activities[currentVideoIndex].resources}
        activities={activities}
        onNextPage={() => console.log("Siguiente página")}
      />
    </Box>
  );
};

export default PasosIniciales;
