import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ServiceCard from "../../components/cards/cardDeServicios/cardDeServicios";


const services = [
  {
    link: "/servicios/redes-sociales",
    image: "./images/brandingDeMarca.png",
    title: "CREACIÓN DE MARCA (BRANDING/RE-BRANDING)",
    description:
      "Diseñamos y desarrollamos la identidad visual y estratégica de tu marca, asegurando coherencia, diferenciación y conexión con tu público objetivo.",
  },
  {
    link: "/servicios/redes-sociales",
    image: "./images/redesSocialesBaner.jpg",
    title: "GESTIÓN PROFESIONAL DE REDES SOCIALES",
    description:
      "Creamos estrategias efectivas para redes sociales, aumentando la interacción y el alcance de tu marca con contenido de valor y campañas dirigidas.",
  },
  {
    link: "/servicios/publicidad-digital",
    image: "./images/publicidadDigital.png",
    title: "PUBLICIDAD DIGITAL PARA MAYOR ALCANCE",
    description:
      "Optimiza tu inversión en anuncios digitales con campañas estratégicas que aumentan la visibilidad y conversión de tu negocio.",
  },
  {
    link: "/servicios/email-marketing",
    image: "./images/emailMarketing.jpg",
    title: "EMAIL MARKETING QUE CONVIERTE",
    description:
      "Diseñamos campañas de email marketing personalizadas para fidelizar clientes y aumentar las ventas de tu negocio.",
  },
  {
    link: "/servicios/inbound-marketing",
    image: "./images/inboundMarketing.png",
    title: "ESTRATEGIAS EFECTIVAS DE INBOUND MARKETING",
    description:
      "Atraemos y convertimos clientes potenciales a través de contenido relevante, automatización y estrategias de conversión efectivas.",
  },
  {
    link: "/servicios/asesoria-inbound",
    image: "./images/asesoriaDeMarketing.png",
    title: "ASESORÍA DE MARKETING PARA CRECIMIENTO EMPRESARIAL",
    description:
      "Brindamos consultoría y estrategias de marketing personalizadas para ayudar a tu empresa a crecer y destacarse en el mercado.",
  },
  {
    link: "/servicios/desarrollo-software",
    image: "./images/desarrolloDeSoftwareAMedida.jpg",
    title: "DESARROLLO DE SOFTWARE A MEDIDA",
    description:
      "Creamos soluciones tecnológicas personalizadas para optimizar procesos, mejorar la productividad y potenciar la competitividad de tu negocio.",
  },
];
const ServiceList = ({ slidesToShow = 2 }) => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey(prevKey => prevKey + 1);  // Forzamos actualización del carrusel
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,  // DETIENE el autoplay cuando el mouse está sobre el slider
    swipeToSlide: true,
    draggable: true,
    touchThreshold: 10,
    cssEase: "linear",
    arrows: false,
    responsive: [
      { breakpoint: 1400, settings: { slidesToShow: Math.min(1, slidesToShow) } },
      { breakpoint: 1200, settings: { slidesToShow: 1 } },
    ],
  };
  

  return (
    <div style={{ padding: "20px", position: "relative" }}>
      <Slider key={key} {...settings}>
        {services.map((service, index) => (
          <div key={index} style={{ padding: "10px" }}>
            <ServiceCard
              title={service.title}
              description={service.description}
              image={service.image}
              link={service.link}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ServiceList;
