import React, { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";

const MarqueeBanner = ({ texts = [] }) => {
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const [translateX, setTranslateX] = useState(0);
  const speed = 3; // Velocidad de desplazamiento (px/s)

  useEffect(() => {
    const updatePosition = () => {
      setTranslateX((prev) => {
        const containerWidth = containerRef.current?.offsetWidth || 0;
        const contentWidth = contentRef.current?.offsetWidth || 0;
        return prev <= -contentWidth ? containerWidth : prev - 1;
      });
    };
    const interval = setInterval(updatePosition, 50 / speed);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      ref={containerRef}
      sx={{
        overflow: "hidden",
        whiteSpace: "nowrap",
        display: "flex",
        alignItems: "center",
        width: "100%",
        backgroundColor: "#065a7c",
        color: "white",
        padding: "30px 0px",
        position: "relative",
      }}
    >
      <Box
        ref={contentRef}
        sx={{
          display: "flex",
          gap: "10px",
          position: "absolute",
          transform: `translateX(${translateX}px)`,
        }}
      >
        {texts.map((text, index) => (
          <Typography key={index} variant="h6">
            {text} {index !== texts.length - 1 && "-"}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default MarqueeBanner;
