import React from "react";
import PageBanner from "../components/banners/BannerPage/pageBanner";
import ServicesGrid from "../portfolio/components/ServicesGrid";
import Footer from "../footer/page";
import { Box, Grid2 } from "@mui/material";
import MarqueeBanner from "../components/banners/cartelSlider"

const Portfolio = () => {
  return (
    <Box
      sx={{
        padding: 0,
        margin: 0,
        width: "100%",
        height: "100%",
      }}
    >
      {/* Envío de props al componente BannerDePaginaPrincipal */}
      <PageBanner
          image="images/bannerPages.png"
          title="CLIENTES QUE TRABAJAN O TRABAJARON CON NOSOTROS."
          description="SOMOS ESPECIALISTAS EN MARKETING INTERNACIONAL Y ESTRATEGIAS DIGITALES"
          subtitle="Te mostramos el impacto de nuestros esfuerzos…"
        />
        <Box>
        <MarqueeBanner
          texts={[
            "Financia tu proyecto hasta en 4 cuotas, sin intereses",
            "Reclama descuentos irresistibles por cada servicio",
            "Capitaliza tu negocio en INTERNET de forma 100% segura",
          ]}
        />
      </Box>
      <Grid2 container sx={{ padding: 0, margin: 0, width: "100%", marginTop: "50px" }}>

        <ServicesGrid />
      </Grid2>
      <Footer />
    </Box>
  );
};

export default Portfolio;
