import React from "react";
import { Box, Typography } from "@mui/material";

const TituloSubtituloCentro = ({
  title,
  subtitle,
  titleVariant = "h4",
  subtitleVariant = "h6",
  titleColor = "text.primary",
  subtitleColor = "text.secondary",
  backgroundColor = "#trasparent",
  ...rest
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor,
        alignItems: {md: "center"},
      }}
      {...rest}
    >
      {/* Título */}
      <Typography
        variant={titleVariant}
        sx={{
          width: {xs: "90%", md: "80%"},
          color: titleColor,
          marginBottom: 2,
          fontWeight: "500",
          textAlign: {xs: "left", md: "center"}, 
          fontSize: "2.5rem",
          
          
        }}
      >
        {title}
      </Typography>

      {/* Subtítulo */}
      <Typography
        variant= "primary"
        sx={{
          color: subtitleColor,
          fontSize: "1.2rem",
          textAlign: {xs: "left", md: "center"}, 
          width: {xs: "100%", md: "80%"}

        }}
      >
        {subtitle}
      </Typography>
    </Box>
  );
};

export default TituloSubtituloCentro;
