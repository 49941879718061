import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NavegacionInternaServicios from "./subNav/subnavegacionServicios";
import NavegacionInternaSoluciones from "./subNav/subnavegacionSoluciones";
import NavegacionInternalSectores from "./subNav/subnavegacionSectores";
import NavegacionInternalRecursos from "./subNav/subnavegacionRecursos";
import UneteALaComunidad from "../../components/botones/comunidades/botónComunidad";
import BtnPrimary from "../../components/botones/BtnPrimary";

const Servicios = () => <NavegacionInternaServicios />;
const Soluciones = () => <NavegacionInternaSoluciones />;
const Sectores = () => <NavegacionInternalSectores />;
const Recursos = () => <NavegacionInternalRecursos />;

const MenuDeNavegacion = () => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [currentComponent, setCurrentComponent] = useState(null);

  const location = useLocation(); // Detecta la ubicación actual
  const navigate = useNavigate(); // Navega entre rutas

  const menuItems = [
    { label: "Servicios", component: <Servicios />, path: null },
    { label: "Soluciones", component: <Soluciones />, path: null },
    { label: "Sectores", component: <Sectores />, path: null },
    { label: "Recursos", component: <Recursos />, path: null },
    { label: "Blog", component: null, path: "/" },
  ];

  useEffect(() => {
    setCurrentComponent(null); // Cierra el submenú cuando cambia la ruta
    setSelectedItem(null); // Deselecciona cualquier elemento del menú
  }, [location.pathname]);
  

  // Actualiza el estado seleccionado basado en la ruta
  useEffect(() => {
    const currentIndex = menuItems.findIndex(
      (item) => item.path === location.pathname
    );
    setSelectedItem(currentIndex !== -1 ? currentIndex : null);
  }, [location.pathname]);

  // Bloquear el scroll cuando hay un componente activo
  useEffect(() => {
    if (currentComponent) {
      document.body.style.overflow = "hidden"; // Bloquea el scroll
    } else {
      document.body.style.overflow = "auto"; // Restaura el scroll cuando se cierra el componente
    }
  }, [currentComponent]);

  const closeOverlay = () => {
    setCurrentComponent(null);
    setSelectedItem(null);
  };

  const handleLogoClick = () => {
    setCurrentComponent(null); // Cierra cualquier submenú abierto
    setSelectedItem(null); // Deselecciona cualquier item
    navigate("/"); // Redirige al inicio
  };

  const handleMenuItemClick = (item, index) => {
    if (item.path) {
      navigate(item.path);
      setSelectedItem(index);
      setCurrentComponent(null); // 🔹 Cierra cualquier menú abierto
    } else {
      if (selectedItem === index) {
        // 🔹 Si ya está abierto, ciérralo
        setSelectedItem(null);
        setCurrentComponent(null);
      } else {
        // 🔹 Si no está abierto, ábrelo
        setSelectedItem(index);
        setCurrentComponent(item.component);
      }
    }
  };
  

  return (
    <Box>
      {/* Menú de Navegación */}
      <Box
        sx={{
          width: "100%",
          height: "65px",
          display: "flex",
          justifyContent: "space-between",
          padding: "10px 10px",
          backgroundColor: "#fff",
          boxShadow: currentComponent
            ? "0px 1px 1px rgba(0, 0, 0, 0.1)"
            : "0px 2px 4px rgba(0, 0, 0, 0.1)",
          position: "sticky",
          top: 0,
          zIndex: 10000,
          transition: "box-shadow 0.1s ease",
        }}
      >
        <Box sx={{ display: "flex", gap: 4, alignItems: "center", ml: 2 }}>
          {/* Logo con redirección al inicio */}
          <Box
            sx={{ display: "flex", cursor: "pointer" }}
            onClick={handleLogoClick}
          >
            <img
              src="/images/logo-hitpoly.png"
              alt="Hitpoly Logo"
              style={{ width: "auto", height: "40px" }}
            />
          </Box>

          {/* Menú */}
          <Box
            sx={{
              display: "flex",
              listStyle: "none",
              gap: "20px",
              margin: 0,
              padding: 0,
            }}
          >
            {menuItems.map((item, index) =>
              item.path ? (
                <a
                  key={index}
                  href={item.path}
                  target={item.path.startsWith("http") ? "_blank" : "_self"} // Abrir en nueva pestaña si es un enlace externo
                  rel={
                    item.path.startsWith("http")
                      ? "noopener noreferrer"
                      : undefined
                  }
                  style={{
                    textDecoration: "none",
                    padding: "5px",
                    color:
                      hoveredItem === index ||
                      selectedItem === index ||
                      (location.pathname === item.path && item.label === "Blog")
                        ? "#211E26"
                        : "rgba(0, 0, 0, 0.6)",
                    transition: "color 0.3s ease",
                  }}
                  onMouseEnter={() => setHoveredItem(index)}
                  onMouseLeave={() => setHoveredItem(null)}
                >
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "14px", fontWeight: "400" }}
                  >
                    {item.label}
                  </Typography>
                </a>
              ) : (
                <Box
                  key={index}
                  sx={{
                    position: "relative",
                    cursor: "pointer",
                    padding: "5px",
                    color:
                      hoveredItem === index || selectedItem === index
                        ? "#211E26"
                        : "rgba(0, 0, 0, 0.6)",
                    transition: "color 0.3s ease",
                  }}
                  onMouseEnter={() => setHoveredItem(index)}
                  onMouseLeave={() => setHoveredItem(null)}
                  onClick={() => handleMenuItemClick(item, index)} // Maneja el clic
                >
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "14px", fontWeight: "400" }}
                  >
                    {item.label}
                  </Typography>
                  {(hoveredItem === index || selectedItem === index) && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translateX(-50%)",
                        marginTop: "1px",
                      }}
                    >
                      <ExpandMoreIcon
                        sx={{ fontSize: "16px", color: "#211E26" }}
                      />
                    </Box>
                  )}
                </Box>
              )
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "20px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <UneteALaComunidad />
          <BtnPrimary
            text="Asesoria Personalizada"
            to="https://meetings.hubspot.com/hitpoly?uuid=34a2179d-26a9-46dc-9eae-98738ae67fae"
          />
        </Box>
      </Box>

      {/* Overlay y Componente dinámico */}
      {currentComponent && (
        <>
          {/* Fondo oscuro */}
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 99,
            }}
            onClick={closeOverlay}
          />

          {/* Contenido dinámico */}
          <Box
            sx={{
              position: "absolute",
              top: "62px",
              left: "0",
              width: "100%",
              zIndex: 1000,
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#fff",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "10px",
                right: "20px",
                width: "30px",
                height: "30px",
                backgroundColor: "#F21C63",
                color: "#fff",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                zIndex: 1001,
              }}
              onClick={closeOverlay}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  lineHeight: 1,
                  userSelect: "none",
                }}
              >
                X
              </Typography>
            </Box>
            <Box
              sx={{
                maxWidth: "100%",
                padding: "20px",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              {currentComponent}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default MenuDeNavegacion;
