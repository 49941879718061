import LayoutDynamicPage from "../landing/LayoutDinamicPage";
import programaDeSetters from "./data/programaDeSetters.json";


const ProgramaDeSetters = () => {
  const {
    portadaProps,
    buyerPersonProps,
    menuItems,
    tituloCentroProps,
    sectionVideoProps,
    acordeonInteligenteProps,
    acordeonInteligenteImagenProps,
    cartasResultadosProps,
    seccionCuerpoImagenData,
    datosTestimonios,
    adicionalescartasResultadosProps,
    SeccionConTituloYAcordeonProps,
    llamadoAlaAccionProps,
  } = programaDeSetters;
  return (
    <LayoutDynamicPage
      portadaProps={portadaProps}
      menuItems={menuItems}
      buyerPersonProps={buyerPersonProps}
      tituloCentroProps={tituloCentroProps}
      sectionVideoProps={sectionVideoProps}
      acordeonInteligenteProps={acordeonInteligenteProps}
      acordeonInteligenteImagenProps={acordeonInteligenteImagenProps}
      cartasResultadosProps={cartasResultadosProps}
      seccioneCuerpoImagenData={seccionCuerpoImagenData}
      datosTestimonios={datosTestimonios}
      adicionalescartasResultadosProps={adicionalescartasResultadosProps}
      SeccionConTituloYAcordeonProps={SeccionConTituloYAcordeonProps}
      llamadoAlaAccionProps={llamadoAlaAccionProps}
    />
  );
};

export default ProgramaDeSetters;
