import React, { useState, useEffect } from "react";
import { Box, Grid, IconButton } from "@mui/material";
import ProfileList from "./setters/listaPerfil";
import ProfileLayout from "./setters/profileLayout";
import profileData from "./setters/profiles.json";
import Footer from "../footer/page";
import SettersStatus from "./setters/contabilizadorDeDisponibilidad/contabilizadorSetters";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const PerfilesSettesDigital = () => {
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [showProfile, setShowProfile] = useState(false);

  // Mostrar automáticamente el primer perfil solo en pantallas grandes
  useEffect(() => {
    if (window.innerWidth > 600) {
      setSelectedProfile(profileData[0] || null);
    }
  }, []);

  const handleProfileSelect = (profile) => {
    setSelectedProfile(profile);
    if (window.innerWidth <= 600) {
      setShowProfile(true);
    }
  };

  const handleBackToList = () => {
    setShowProfile(false);
  };

  return (
    <>
      <Box
        sx={{
          padding: { xs: "0px 20px", md: "0px 100px" },
          marginTop: { xs: "20px", md: "80px" },
          marginBottom: { xs: "20px", md: "80px" },
        }}
      >
        <Box sx={{ marginBottom: { xs: "20px", md: "40px" } }}>
          <SettersStatus />
        </Box>
        <Box sx={{ p: { xs: 0, md: 0 }, mt: { xs: 2, md: 0 } }}>
          <Grid container spacing={3}>
            {/* Lista de perfiles */}
            <Grid item xs={12} md={3} sx={{ position: "relative", minHeight: "300px" }}>
              <Box
                sx={{
                  width: "100%",
                  maxHeight: "100vh",
                  overflowY: "auto",
                  backgroundColor: "white",
                  borderRadius: "8px",
                }}
              >
                <ProfileList profiles={profileData} onProfileSelect={handleProfileSelect} />
              </Box>
            </Grid>

            {/* Vista del perfil */}
            <Grid item xs={12} md={9}>
              {/* En móviles: Muestra el perfil solo si se seleccionó uno */}
              {showProfile && window.innerWidth <= 600 ? (
                <Box
                  sx={{
                    position: "fixed",
                    top: "60px",
                    left: 0,
                    width: "100%",
                    height: "calc(100vh - 60px)",
                    backgroundColor: "white",
                    zIndex: 1000,
                    boxShadow: "none",
                    transition: "transform 0.3s ease-in-out",
                    transform: "translateX(0%)",
                    overflowY: "auto",
                  }}
                >
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: "20px",
                      left: "20px",
                      zIndex: 2000,
                      backgroundColor: "rgba(0, 0, 0, 0.2)",
                    }}
                    onClick={handleBackToList}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                  <ProfileLayout profile={selectedProfile} />
                </Box>
              ) : null}

              {/* En escritorio: Siempre mostrar el primer perfil o el seleccionado */}
              {!showProfile && selectedProfile && window.innerWidth > 600 && (
                <ProfileLayout profile={selectedProfile} />
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default PerfilesSettesDigital;
