import { Box, Typography, Button } from "@mui/material";

const OfertaEspecial = () => {
  return (
    <Box
      sx={{
        textAlign: { xs: "left", md: "center" }, // Alineación responsive
        padding: "24px",
        margin: "5rem auto", // Margen similar al de Soluciones
        width: { xs: "90%", md: "68%" }, // Adaptabilidad en tamaños de pantalla
        borderRadius: "8px",
      }}
    >
      {/* Título */}
      <Typography variant="h4" component="div" gutterBottom>
        Impulsamos el crecimiento digital de profesionales y empresas para una capitalización efectiva  
      </Typography>

      {/* Descripción */}
      <Typography
        variant="body2"
        color="text.secondary"
        gutterBottom
        fontSize="1.3rem"
      >
        Contamos con el talento y los conocimientos clave para ayudar a
        profesionales y empresas a aprovechar al máximo el entorno digital.
        Mientras los profesionales se especializan y avanzan en su carrera, las
        marcas crecen con estrategias que convierten su presencia online en
        rentabilidad real.
      </Typography>

      {/* Botón */}
      <Button
        variant="contained"
        sx={{
          background: "#0097b2",
          marginTop: "3.5rem",
          padding: "12px 24px",
          textTransform: "none",
          fontWeight: "bold",
        }}
      >
        Conoce más de nosotros
      </Button>
    </Box>
  );
};

export default OfertaEspecial;
