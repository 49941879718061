import React from "react";
import {
  Grid,
  Typography,
  Button,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";

const CardModernizacion = ({ title, description, items }) => {
  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} md={4}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ textAlign:"left",}}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ textAlign:"left",}}
        >
          {description}
        </Typography>
      </Grid>

      {items.map((item, index) => (
        <Grid item xs={12} md={4} key={index}>
          <Card>
            <CardMedia component="img" height="200" image={item.image} alt={item.alt} />
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <Typography variant="h6" gutterBottom>
                {item.text}
              </Typography>
              <Button sx={{background: "#f21c63"}} variant="contained" href={item.link}>
                {item.buttonText}
              </Button>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default CardModernizacion;
