import React from "react";
import { Box, Typography, Button } from "@mui/material";

const BannerBuyerPerson = ({
  title,
  description,
  buttonText,
  buttonLink,
  bgColor,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: bgColor || "#0b8db5",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        py: 10,
        px: 2,
      }}
    >
      <Box
        sx={{
          width: { xs: "90%", md: "80%" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          gap: "20px",
        }}
      >
        <Typography variant="h4" color="white" gutterBottom>
          {title}
        </Typography>
        <Typography
          sx={{ fontSize: "1rem" }}
          variant="body1"
          color="white"
          gutterBottom
        >
          {description}
        </Typography>
        <Button variant="contained" color="secondary" href={buttonLink}>
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
};

export default BannerBuyerPerson;
