import React from 'react';
import { Box } from '@mui/material';

const ImagenPersonalizaReutilizable = ({
  images = [],  // Recibe un array en lugar de un solo src
  width = '100%',
  height = '100%',  
  borderRadius = 0,
  sx = {},
}) => {
  return (
    <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
      {images.map((image, index) => (
        <Box
          key={index}
          component="img"
          src={image.src}
          alt={image.alt || 'Imagen'}
          sx={{
            width,                   
            height,                 
            objectFit: 'contain',    
            borderRadius,           
            ...sx,
          }}
        />
      ))}
    </Box>
  );
};

export default ImagenPersonalizaReutilizable;
