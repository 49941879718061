import { memo, useMemo } from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const BtnPrimary = ({
  text = "Asesoría Personalizada",
  backgroundColor = "#F21C63",
  hoverColor = "#bf0a46",
  fontSize = "16px",
  fontWeight = "500",
  to,
  onClick,
  ...rest
}) => {
  const buttonStyles = useMemo(
    () => ({
      width: "100%",
      backgroundColor,
      color: "#fff",
      borderRadius: "5px",
      padding: "10px 30px",
      fontSize,
      fontWeight,
      boxShadow: "none",
      transition: "all 0.3s ease",
      textTransform: "none",
      "&:hover": { backgroundColor: hoverColor },
    }),
    [backgroundColor, hoverColor, fontSize, fontWeight]
  );

  const ButtonComponent = (
    <Button
      sx={buttonStyles}
      onClick={onClick}
      {...rest}
    >
      {text}
    </Button>
  );

  return to ? (
    <Link
      to={to}
      style={{ textDecoration: "none" }}
    >
      {ButtonComponent}
    </Link>
  ) : (
    ButtonComponent
  );
};

export default memo(BtnPrimary);
