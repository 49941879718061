import React from 'react';
import { Box } from '@mui/material';

const ImagenPersonalizada = ({
  src,
  alt = 'Imagen',
  width = '100%',
  height = '100%',  
  borderRadius = 5,
  sx = {},
}) => {
  return (
    <Box
      component="img"
      src={src}
      alt={alt}
      sx={{
        width,                  
        height,                 
        objectFit: 'contain',   
        borderRadius,           
        ...sx,
      }}
    />
  );
};

export default ImagenPersonalizada;
