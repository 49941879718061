import React from "react";
import { Box, Button, Typography } from "@mui/material";

const BannerSoluciones = ({ title, imgSrc, imgAlt, textBotton, TextColor, link }) => {
  return (
    <Box 
      sx={{ 
        display: "flex", 
        flexDirection: { xs: "column", md: "row" }, 
        alignItems: "center", 
        justifyContent: "center",
        backgroundColor: "#f8f9fa", 
        paddingX: { xs: 2, md: 0 }, // Padding en móviles
        paddingY: 0 
      }}
    >
      {/* Imagen */}
      <Box 
        component="img"
        src={imgSrc}
        alt={imgAlt}
        sx={{ 
          width: { xs: "100%", md: "50%" }, 
          height: "auto", 
          objectFit: "cover",
          height: {md: "80vh"},
        }}
      />

      {/* Texto y botón */}
      <Box 
        sx={{ 
          width: { xs: "100%", md: "50%" }, 
          textAlign: "left", 
          paddingX: { xs: 2, md: 8 }, // Espacio lateral en móviles
          display: "flex",
          flexDirection: "column"

        }}
      >
        <Typography
          sx={{ 
            marginTop: {xs:"30px", md: "50px"},
            fontSize: { 
              xs: "2.3rem",  // Celulares pequeños
              sm: "2.1rem",  // Celulares grandes / Tablets pequeñas
              md: "1.6rem",  // Tablets grandes / Laptops pequeñas
              lg: "2rem",  // Escritorios grandes
              xl: "3rem"   // Monitores 4K
            },
            fontWeight: "bold",
            textAlign: "left", 
          }}
          gutterBottom
        >
          {title} 
          <span style={{ color: "#f21c63" }}> {TextColor}</span>
        </Typography>

        <Button
          sx={{ 
            width: {xs: "100%", md: "50%"},
            background: "#0b8db5", 
            marginTop: 2,
            marginBottom: 6,
            fontSize: { xs: "1rem", md: "1.2rem" }, 
            padding: { xs: "10px 20px", md: "12px 24px" }
          }}
          variant="contained"
          size="large"
          component="a"
          href={link}
          target="_blank"
          rel="noopener noreferrer" 
        >
          {textBotton}
        </Button>
      </Box>
    </Box>
  );
};

export default BannerSoluciones;
