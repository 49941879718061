import React from "react";
import PageBanner from "../components/banners/BannerPage/pageBanner";
import AboutUsSection from "../nosotros/components/AboutUsSection";
import CeoCard from "../nosotros/components/CeoCard";
import StepGrid from "../nosotros/components/StepGrid";
import TeamMembers from "./components/TeamMembers";
import ServiceReviews from "./../home/components/ServiceReviews";
import FAQSection from "./../nosotros/components/FAQSection";
import Footer from "../footer/page";
import { Box } from "@mui/material";

const Nosotros = () => {
  return (
    <Box>
      
      {/* Usa BannerDePaginaPrincipal con las props */}
      <Box sx={{marginBottom: "50px"}}>
      <PageBanner 
        image="images/nosotrosBanner.png" 
        title="TE AYUDAMOS A INTERNACIONALIZAR TU MARCA, EN MENOS DE 3 MESES." 
        description="SOMOS ESPECIALISTAS EN MARKETING INTERNACIONAL Y ESTRATEGIAS DIGITALES" 
        subtitle="Contamos con profesionales de primer nivel..."
      />
      </Box>

      <AboutUsSection />
      { /* <TeamMembers /> */}
      <StepGrid />
      <Box sx={{marginTop: "150px"}}>
      <ServiceReviews />
      </Box>
      <FAQSection />
      <Footer />
    </Box>
  );
};

export default Nosotros;
